import React from 'react';
import PropTypes from 'prop-types';
import { Rect } from 'react-konva';
import { positionPropTypes } from '../../../../../util/flow/types';

export default function EditBackground({
  x, y, width, height, visible,
}) {
  if (!visible) { return null; }
  return (
    <Rect x={x} y={y} width={width} height={height} fill="#40454D" cornerRadius={[0, 4, 4, 0]} />
  );
}

EditBackground.propTypes = {
  ...positionPropTypes,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
};
