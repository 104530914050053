import { Controller } from 'stimulus';
import notify from '../../app/notify';

export default class extends Controller {
  connect() {
    const $elem = $(this.element);
    const type = $elem.hasClass('flash-alert') ? 'error' : 'success';
    const text = $elem.text().trim();
    if (!text) { return; }
    const [title, message] = text.split('#');
    notify(title, message || '', type);
    $elem.remove();
  }
}
