import { Controller } from 'stimulus';

export default class extends Controller {
  change(e) {
    Turbolinks.visit(`${this.urlPrefix}${e.target.value}`);
  }

  get urlPrefix() {
    return this.data.get('url-prefix');
  }
}
