import React from 'react';
import PropTypes from 'prop-types';

export default function NodePreviewNavigator({ index, total, onNav }) {
  if (total <= 1) { return null; }

  return (
    <div className="si-preview-nav">
      <a role="button" onClick={() => onNav(index === 0 ? total - 1 : index - 1)} tabIndex={-1}>
        <i className="fa fa-chevron-left" />
      </a>
      <span>
        {index + 1}
        /
        {total}
      </span>
      <a role="button" onClick={() => onNav((index + 1) % total)} tabIndex={-1}>
        <i className="fa fa-chevron-right" />
      </a>
    </div>
  );
}

NodePreviewNavigator.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onNav: PropTypes.func.isRequired,
};
