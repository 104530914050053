export const TYPE_UPDATE_PERMISSIONS = 'TYPE_UPDATE_PERMISSIONS';

export default function reducer(draft, action) {
  switch (action.type) {
    case TYPE_UPDATE_PERMISSIONS:
      draft.permissions = action.permissions;
      break;
    default:
      break;
  }
}
