import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Plug from './Plug';
import PlugLabel from './Plug/PlugLabel';
import {
  nodeConfigPropType, outputPropType, connectionPropType, nodePropType,
} from '../../../util/flow/types';
import { CONNECTION_HEIGHT, CONNECTION_MARGIN } from '../util/theme';
import { fieldComponent } from './Inputs/Field';

export function outputsHeight(nodeConfig) {
  const count = nodeConfig.outputs?.length ?? 0;
  return count * CONNECTION_HEIGHT + _.max([count - 1, 0]) * CONNECTION_MARGIN;
}

function Output({
  node, output, index, offset, connections,
}) {
  const top = offset + (40 * index);
  return (
    <>
      <PlugLabel x={140} y={top} text={output.name} align="right" />
      <Plug x={280} y={top} direction="out" type="data" dataType={output.type} secondaryDataType={output.arrayType} name={output.name} connections={connections} node={node} />
    </>
  );
}
Output.propTypes = {
  node: nodePropType.isRequired,
  output: outputPropType.isRequired,
  index: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};

export default function Outputs({ node, config, connections }) {
  const offset = 10 + _.size(config.signals) * 40;
  const outputs = (config.outputs || []).map((output, index) => (
    <Output
      key={output.name}
      node={node}
      offset={offset}
      index={index}
      output={output}
      connections={_.filter(connections, { leftId: node.id, leftKey: output.name })}
    />
  ));
  return (
    <>
      {outputs}
    </>
  );
}

Outputs.propTypes = {
  node: nodePropType.isRequired,
  config: nodeConfigPropType.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};
