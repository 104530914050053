import { Controller } from 'stimulus';
import _ from 'lodash';
import $ from 'jquery';
import { triggerEvent } from '../../app/event';

export default class extends Controller {
  static targets = ['list', 'templateNewItem'];

  connect() {
    // Allow replace-text controller to work
    // Ideally, this should come from an event
    setTimeout(() => this.init(), 200);
  }

  init() {
    this.templateNewItem = _.template(this.templateNewItemTarget.innerHTML);
  }

  // eslint-disable-next-line class-methods-use-this
  remove(e) {
    const item = e.currentTarget.closest('.item-multiple');
    const destroyField = item.querySelector('input[id$=_destroy]');
    item.innerHTML = '';

    if (destroyField) {
      destroyField.value = 'true';
      item.appendChild(destroyField);
    }
    this.triggerChange();
  }

  add(event) {
    $(this.listTarget).append(
      _.map(event.data || [{}], (data) => $('<div>').html(
        this.templateNewItem({ key: _.uniqueId((new Date()).getTime()), ...data }),
      )),
    );
    this.triggerChange();
  }

  triggerChange() {
    setTimeout(() => triggerEvent($(this.listTarget).parents('form')[0], 'change'), 200);
  }
}
