import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Plug from './Plug';
import PlugLabel from './Plug/PlugLabel';
import { nodeConfigPropType, nodePropType, connectionPropType } from '../../../util/flow/types';
import { CONNECTION_HEIGHT, CONNECTION_MARGIN } from '../util/theme';

export function signalsHeight(nodeConfig) {
  const count = nodeConfig.signals?.length ?? 0;
  return count * CONNECTION_HEIGHT + _.max([count - 1, 0]) * CONNECTION_MARGIN;
}

function Signal({
  node, signal, index, connections,
}) {
  const top = 10 + (40 * index);
  return (
    <>
      <PlugLabel x={140} y={top} text={signal} align="right" />
      <Plug x={280} y={top} direction="out" type="logic" connections={connections} node={node} name={signal} />
    </>
  );
}
Signal.propTypes = {
  node: nodePropType.isRequired,
  signal: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};

export default function Signals({ node, config, connections }) {
  const signals = (config.signals || [])
    .map((signal, index) => (
      <Signal
        key={signal}
        node={node}
        index={index}
        signal={signal}
        connections={_.filter(connections, { leftId: node.id, leftKey: signal })}
      />
    ));
  return (
    <>
      {signals}
    </>
  );
}

Signals.propTypes = {
  node: nodePropType.isRequired,
  config: nodeConfigPropType.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};
