function cancelIntermediate(draft) {
  if (draft.originalScene) {
    draft.scene = draft.originalScene;
  }
}

export default function reducer(draft, action) {
  switch (action.type) {
    case 'cancel-intermediate':
      return cancelIntermediate(draft, action);
    case 'update-scene':
      draft.scene = action.scene;
      if (!action.scene.intermediate) {
        draft.originalScene = action.scene;
      }
      break;
    default: break;
  }
}
