import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Group } from 'react-konva';
import Theme, { CONNECTION_HEIGHT } from '../../../util/theme';
import { positionPropTypes } from '../../../../../util/flow/types';
import EditText from '../EditText';
import { useDispatch } from '../../../util/hooks/core';
import Button from '../../../util/Button';

export default function ArrayTextField({
  x, y, text, imageComponent, onChange, onButtonAction, editable,
}) {
  const dispatch = useDispatch();
  const [buttonVisible, setButtonVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);

  return (
    <Group
      width={130}
      height={CONNECTION_HEIGHT}
      onMouseEnter={() => setButtonVisible(true)}
      onMouseLeave={() => setButtonVisible(false)}
    >
      <EditText
        x={x}
        y={y}
        text={text}
        showIndicator
        editable={editable}
        onChange={onChange}
        onEditModeChange={setEditMode}
        onChangeIntermediate={(v) => onChange(v, true)}
        cancelIntermediateChanges={() => dispatch({ type: 'cancel-intermediate' })}
      />
      <Button
        x={x + 130}
        y={y + 0}
        visible={!editMode && buttonVisible}
        cornerRadius={4}
        fill={Theme.COLOR_PRIMARY_BG}
        onClick={onButtonAction}
        Image={imageComponent}
      />
    </Group>
  );
}

ArrayTextField.propTypes = {
  ...positionPropTypes,
  text: PropTypes.string.isRequired,
  imageComponent: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onButtonAction: PropTypes.func.isRequired,
};
