import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-konva';
import { positionPropTypes } from '../../../../util/flow/types';
import { PLUG_LABEL_STYLE } from '../../util/theme';

const PlugLabel = ({
  x, y, text, align, ...options
}, ref) => (
  <Text
    x={x}
    y={y}
    width={PLUG_LABEL_STYLE.width}
    height={30}
    lineHeight={PLUG_LABEL_STYLE.lineHeight / PLUG_LABEL_STYLE.fontSize}
    text={text}
    fill="white"
    fontFamily={PLUG_LABEL_STYLE.fontFamily}
    fontSize={PLUG_LABEL_STYLE.fontSize}
    align={align}
    verticalAlign="middle"
    ref={ref}
    listening={false}
    {...options} // eslint-disable-line react/jsx-props-no-spreading
  />
);

const ReffedPlugLabel = React.forwardRef(PlugLabel);

PlugLabel.propTypes = {
  ...positionPropTypes,
  text: PropTypes.string,
  align: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

PlugLabel.defaultProps = {
  visible: true,
  text: '',
};

export default ReffedPlugLabel;
