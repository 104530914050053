import React, { useState } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';
import { Group, Rect } from 'react-konva';
import { positionPropTypes, nodePropType } from '../../../../util/flow/types';
import { addPoints } from '../../../../util/flow/ui/point';
import Theme from '../../util/theme';
import Button from './Button';
import { getViewportPosition } from '../../util/hooks/viewport';
import { useDispatch } from '../../util/hooks/core';

export function useContextMenuState(node) {
  const [contextMenuState, setContextMenuState] = useState({ visible: false, x: 0, y: 0 });
  const onClick = (e) => {
    if (e.evt.button !== 2) { return setContextMenuState({ visible: false, x: 0, y: 0 }); }
    const stage = e.target.getStage();
    stage.setPointersPositions(e.evt);
    const position = addPoints(
      getViewportPosition(stage),
      { x: -node.positionX, y: -node.positionY },
    );
    setContextMenuState({ visible: !contextMenuState.visible, ...position });
  };
  return [contextMenuState, onClick];
}

function markDestroyed(node) {
  return produce(node, (draft) => {
    draft.destroyed = true;
  });
}

function toggleEditable(node) {
  return produce(node, (draft) => {
    draft.editable = !draft.editable;
  });
}

export default function ContextMenu({
  visible, node, x, y,
}) {
  const dispatch = useDispatch();

  if (!visible) { return null; }

  return (
    <Group x={x} y={y} width={100} height={80}>
      <Rect x={0} y={0} width={100} height={80} fill="black" cornerRadius={4} />
      <Rect x={1} y={1} width={98} height={78} fill={Theme.COLOR_SCEONDARY_BG} cornerRadius={4} />
      <Button index={0} title={`${node.editable ? 'Lock' : 'Unlock'} Node`} onClick={() => dispatch({ type: 'update-node', node: toggleEditable(node) })} />
      <Button index={1} title="Reset in/out" onClick={() => dispatch({ type: 'reset-node-connections', node })} />
      <Button index={2} last title="Delete" onClick={() => dispatch({ type: 'update-node', node: markDestroyed(node) })} />
    </Group>
  );
}

ContextMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  node: nodePropType.isRequired,
  ...positionPropTypes,
};
