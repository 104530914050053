import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import { triggerEvent } from '../../app/event';

export default class extends Controller {
  connect() {
    this.sortable = new Sortable(this.element, {
      handle: this.data.get('handle'),
      draggable: this.data.get('draggable'),
      ghostClass: this.data.get('ghost-class'),
      chosenClass: this.data.get('chosen-class'),
      dragClass: this.data.get('drag-class'),
      onEnd: (e) => triggerEvent(this.element, 'change', { originalEvent: e }),
      onUnchoose: (e) => triggerEvent(this.element, 'sort-end', { originalEvent: e }),
    });
  }
}
