import { Controller } from 'stimulus';
import _ from 'lodash';

export default class extends Controller {
  static targets = ['li'];

  updateItems(event) {
    _.forEach(this.liTargets, (li) => {
      const visible = _.includes(
        li.dataset.searchText.toLowerCase(),
        event.target.value.toLowerCase(),
      );
      li.classList[visible ? 'remove' : 'add']('hide');
    });
  }
}
