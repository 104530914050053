import React from 'react';
import _ from 'lodash';
import produce from 'immer';
import { editableFieldPropTypes } from '../types';
import Select from './Select';
import { useNodeConfig, useDispatch } from '../../../util/hooks/core';

function valueOf(value, valueField) {
  if (!_.isArray(valueField)) { return value?.[_.camelCase(valueField)]; }
  return _.pick(value, _.map(valueField, _.camelCase));
}

export default function SelectField({
  x, y, input, node, editable, onInteractionStateChange,
}) {
  const nodeConfig = useNodeConfig(node);
  const dispatch = useDispatch();
  const selector = _.find(nodeConfig.selectors, { name: input.selector.name });
  const labelField = _.camelCase(input.selector.label);
  const groupField = _.camelCase(input.selector.group);
  const userValue = _.find(
    selector.values,
    (v) => {
      const nodeInputVal = node?.input?.[_.camelCase(input.name)];
      if (_.isArray(input.selector.value)) {
        // eslint-disable-next-line eqeqeq, max-len
        return _.every(input.selector.value, (vf) => v[_.camelCase(vf)] == nodeInputVal?.[_.camelCase(vf)]);
      }
      // eslint-disable-next-line eqeqeq
      return v[_.camelCase(input.selector.value)] == nodeInputVal;
    },
  );
  const label = userValue?.[labelField] || _.startCase(input.name);
  const options = selector.values.map((v) => ({
    label: v[labelField],
    value: valueOf(v, input.selector.value),
    group: v[groupField],
  }));

  return (
    <Select
      x={x}
      y={y}
      selectedValue={valueOf(userValue, input.selector.value)}
      label={label}
      options={options}
      editable={editable}
      onChange={(value) => dispatch({
        type: 'update-node',
        node: produce(node, (draft) => { draft.input[input.name] = value; }),
      })}
      onInteractionStateChange={onInteractionStateChange}
    />
  );
}

SelectField.propTypes = editableFieldPropTypes;
