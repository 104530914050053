import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['target'];

  toggleTargetsIfChecked(e) {
    const { checked } = e.currentTarget;
    this.targetTargets.forEach((target) => {
      target.classList[checked ? 'remove' : 'add']('hide');
    });
  }
}
