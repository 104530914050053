import { Controller } from 'stimulus';
import _ from 'lodash';

export default class extends Controller {
  static targets = ['mappings', 'mappingTemplate'];

  connect() {
    this.mappingTemplate = _.template(this.mappingTemplateTarget.innerHTML);
    this.sourceElements = JSON.parse(this.data.get('source-elements'));
    this.targetElements = JSON.parse(this.data.get('target-elements'));
  }

  add(event) {
    $(this.mappingsTarget).append(
      _.map(event.data || [{}], (data, index) => $(
        this.mappingTemplate({
          index: $(this.mappingsTarget).children('.mapping-item').length + index,
          source: data,
        }),
      )),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  remove(event) {
    const item = event.target.closest('.mapping-item');
    item.innerHTML = '';
  }

  updateMappingLines(e) {
    const values = $(e.currentTarget).val();
    const container = $(this.mappingsTarget);
    const existing = container.children('.form-row');

    // Append
    container.empty().append(
      _(values)
        .map(_.parseInt)
        .map((id, index) => {
          const source = _.find(this.sourceElements, { id });
          return this.mappingTemplate({ source, index });
        })
        .value(),
    );

    // Update values
    _.forEach(container.children('.form-row'), (row) => {
      const old = existing.filter(`[data-id=${row.dataset.id}]`);
      if (!old.length) { return; }

      _.forEach($(row).find('[data-mapping-attr]'), (input) => {
        if (input.type === 'checkbox') {
          return $(input).prop('checked', old.find(`[data-mapping-attr=${input.dataset.mappingAttr}]`).prop('checked'));
        }
        $(input).val(old.find(`[data-mapping-attr=${input.dataset.mappingAttr}]`).val());
      });
    });
  }

  get newBlockFormController() {
    return this.application.getControllerForElementAndIdentifier(this.newBlockFormTarget, 'migration--new-block');
  }
}
