import React from 'react';
import PropTypes from 'prop-types';
import { positionPropTypes, cornerRadiusPropType } from '../../../../../util/flow/types';
import Button from '../../../util/Button';

export default function EditIndicator({
  x, y, visible, cornerRadius, onClick, Icon, onMouseEnter, onMouseLeave,
}) {
  return (
    <Button
      x={x}
      y={y}
      visible={visible}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cornerRadius={cornerRadius}
      Image={Icon}
    />
  );
}

EditIndicator.propTypes = {
  ...positionPropTypes,
  visible: PropTypes.bool.isRequired,
  cornerRadius: cornerRadiusPropType.isRequired,
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};
