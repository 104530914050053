import { Controller } from 'stimulus';
import _ from 'lodash';
import consumer from '../../app/consumer';

export default class extends Controller {
  static targets = ['row'];

  connect() {
    const self = this;
    this.subscription = consumer.subscriptions.create({ channel: 'MigrationChannel' }, {
      received(message) {
        switch (message.type) {
          case 'migration_event':
            return self.updateRow(message.data.id, message.data.row_html);
          default: break;
        }
      },
    });
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  updateRow(id, html) {
    const row = _.find(this.rowTargets, (el) => parseInt(el.dataset.id, 10) === id);
    if (!row) { return; }
    $(row).replaceWith(html);
  }
}
