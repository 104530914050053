import 'bootstrap-notify';

export default function notify(title, message, type) {
  $.notify(
    { title, message },
    {
      type,
      delay: 3000,
      placement: {
        from: 'bottom',
        align: 'right',
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="success"><span data-notify="title">{1}</span><span data-notify="message">{2}</span></div>',
    },
  );
}

App.notify = notify;
