import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import PlugLabel from '../../Plug/PlugLabel';
import {
  textareaFromTextNode, plugLabelHeight, substringToFitHeight, MAX_EDIT_TEXT_HEIGHT, editTextHeight,
} from '../../../../../util/flow/ui/text';
import { positionPropTypes } from '../../../../../util/flow/types';
import { useActiveElementRef } from '../../../util/hooks/misc';
import { useDispatch, useConfig } from '../../../util/hooks/core';
import { PLUG_LABEL_STYLE } from '../../../util/theme';
import { Pencil, Check } from '../../../util/Images';
import EditBackground from './EditBackground';
import EditIndicator from './EditIndicator';

function useTextAreaEditable({
  editMode, setEditMode, onChange,
  onChangeIntermediate, cancelIntermediateChanges,
}) {
  const textAreaRef = useRef();
  const labelRef = useRef();
  const signalIntermediateChange = useCallback(
    _.throttle(onChangeIntermediate, 500), [onChangeIntermediate],
  );

  const saveAndEndEditMode = useCallback(() => {
    if (textAreaRef.current?.value) {
      onChange(textAreaRef.current?.value);
    }
    setEditMode(false);
  }, [textAreaRef, setEditMode, onChange]);

  useEffect(() => {
    if (!editMode && textAreaRef.current?.parentNode) {
      textAreaRef.current.parentNode.removeChild(textAreaRef.current);
      return;
    }
    if (!editMode) { return; }
    const textNode = labelRef.current;
    if (!textAreaRef.current?.parentNode) {
      textAreaRef.current = textareaFromTextNode(textNode);
      // Always keep MAX_EDIT_TEXT_HEIGHT height to allow expansion of node
      textAreaRef.current.style.height = `${MAX_EDIT_TEXT_HEIGHT}px`;
    }

    // Save on dblclick
    const stage = textNode.getStage();
    stage.on('dblclick.edit_text', () => saveAndEndEditMode());

    const textArea = textAreaRef.current;
    const onKeyDown = (e) => {
      switch (e.keyCode) {
        case 13: // Enter
          if (e.metaKey) { saveAndEndEditMode(); }
          break;
        case 27: // Escape
          setEditMode(false);
          cancelIntermediateChanges();
          break;
        default: break;
      }
    };
    const onInput = (e) => signalIntermediateChange(e.target.value);
    textArea.addEventListener('keydown', onKeyDown);
    textArea.addEventListener('input', onInput);
    return () => {
      textArea.removeEventListener('keydown', onKeyDown);
      textArea.removeEventListener('input', onInput);
      stage.off('dblclick.edit_text');
    };
  }, [editMode, labelRef, setEditMode, saveAndEndEditMode,
    onChange, signalIntermediateChange, cancelIntermediateChanges]);

  return { saveAndEndEditMode, labelRef };
}

function ellipsizeIfRequired(text) {
  const substr = substringToFitHeight(text, MAX_EDIT_TEXT_HEIGHT);
  if (substr.length === text.length) { return text; }
  return `${substr.substring(0, substr.length - 3)}...`;
}

export default function EditText({
  x, y, text, placeholder, onChange, onChangeIntermediate, editable,
  cancelIntermediateChanges, onEditModeChange, showIndicator, ...extraProps
}) {
  const config = useConfig();
  const [editMode, setEditMode] = useState(false);
  const [editIndicatorVisible, setEditIndicatorVisible] = useState(false);
  const dispatch = useDispatch();
  const element = useActiveElementRef({ lockViewport: true });
  const { saveAndEndEditMode, labelRef } = useTextAreaEditable({
    editMode,
    setEditMode,
    onChange,
    onChangeIntermediate,
    cancelIntermediateChanges,
  });
  const debouncedSetEditIndicatorVisible = useCallback(
    _.debounce(setEditIndicatorVisible, 100),
    [setEditIndicatorVisible],
  );

  useEffect(() => {
    onEditModeChange(editMode);
    if (!dispatch) { return; }
    dispatch({
      type: `${editMode ? 'register' : 'unregister'}-active-element`,
      element,
    });
  }, [onEditModeChange, editMode, element, dispatch]);


  const height = EditText.height(text || placeholder);
  const indicatorX = (extraProps.width || PLUG_LABEL_STYLE.width) + 2;
  const actualHeight = plugLabelHeight(text);
  const alignTop = Math.abs(height - actualHeight) >= 5;
  return (
    <>
      <EditBackground
        x={0}
        y={y}
        width={x + indicatorX + 30}
        visible={editMode}
        height={height}
      />
      <PlugLabel
        x={x}
        y={alignTop ? y + 5 : y}
        text={editMode ? (text || placeholder) : ellipsizeIfRequired(text || placeholder)}
        height={height}
        wrap="char"
        align="left"
        ref={labelRef}
        visible={!editMode}
        listening={!config.previewMode && editable}
        opacity={text ? 1 : 0.5}
        verticalAlign={alignTop ? 'top' : 'middle'}
        onDblClick={() => setEditMode(true)}
        onMouseEnter={() => debouncedSetEditIndicatorVisible(true)}
        onMouseLeave={() => debouncedSetEditIndicatorVisible(false)}
        {...extraProps} // eslint-disable-line react/jsx-props-no-spreading
      />
      <EditIndicator
        x={x + indicatorX}
        y={y}
        onMouseEnter={() => debouncedSetEditIndicatorVisible(true)}
        onMouseLeave={() => debouncedSetEditIndicatorVisible(false)}
        visible={editable && (editIndicatorVisible || editMode)}
        cornerRadius={4}
        Icon={editMode ? Check : Pencil}
        onClick={() => (editMode ? saveAndEndEditMode() : setEditMode(true))}
      />
    </>
  );
}

EditText.propTypes = {
  ...positionPropTypes,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeIntermediate: PropTypes.func,
  cancelIntermediateChanges: PropTypes.func,
  onEditModeChange: PropTypes.func,
  showIndicator: PropTypes.bool,
  editable: PropTypes.bool,
};

EditText.defaultProps = {
  onEditModeChange: () => { },
  onChangeIntermediate: () => { },
  cancelIntermediateChanges: () => { },
  showIndicator: false,
  text: null,
  placeholder: null,
  editable: true,
};

EditText.height = (text) => editTextHeight(text);
