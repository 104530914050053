import _ from 'lodash';
import { nodeHasActionInput, getNodeConfig } from '../node';
import { inputY } from '../ui/height';
import { CONNECTION_HEIGHT, CONNECTION_MARGIN } from '../../../components/Flow/util/theme';
import { ioNameFromKey } from './core';
import { addPoints, isPointInsideCircle } from '../ui/point';
import { editTextHeight } from '../ui/text';

function logicPlugOffset(node, nodeConfig, connection) {
  if (node.id === connection.rightId) { return { x: -30, y: 40 }; }


  const index = _.indexOf(nodeConfig.signals, connection.leftKey);
  return { x: 280, y: 40 + index * 40 };
}

function comparatorHashBuilderYOffset(node, connection) {
  let offset = 0;
  const keyParts = connection.rightKey.split('.');
  const values = node.input[_.camelCase(keyParts[0])];
  const conditionIndex = keyParts[1] ?? 0;
  _(values).take(conditionIndex).each((v) => {
    offset += 3 * (CONNECTION_HEIGHT + CONNECTION_MARGIN) + editTextHeight(v?.value ?? 'Value') + CONNECTION_MARGIN;
  });

  const inputIndex = ['typed_field', 'operator', 'value'].indexOf(keyParts[2]) ?? 0;
  offset += inputIndex * (CONNECTION_HEIGHT + CONNECTION_MARGIN);

  return offset;
}

function inputDataPlugOffset(node, nodeConfig, connection, fieldComponentCallback) {
  const input = _.find(nodeConfig.inputs, { name: ioNameFromKey(connection.rightKey) });
  let y = inputY(input, nodeConfig, node, fieldComponentCallback);
  // const index = _.indexOf(nodeConfig.inputs, input);
  y += 40 + (nodeHasActionInput(node.className) ? 40 : 0);
  if (input.type === 'comparator_hash_builder') { y += comparatorHashBuilderYOffset(node, connection, fieldComponentCallback); }
  return { x: -30, y };
}

function outputDataPlugOffset(node, nodeConfig, connection) {
  const index = _.findIndex(nodeConfig.outputs, { name: ioNameFromKey(connection.leftKey) });
  return { x: 280, y: 40 + 40 * (nodeConfig.signals?.length ?? 0) + 40 * index };
}

function dataPlugOffset(node, nodeConfig, connection, inputFieldComponentCallback) {
  const isInput = node.id === connection.rightId;
  if (isInput) {
    return inputDataPlugOffset(node, nodeConfig, connection, inputFieldComponentCallback);
  }
  return outputDataPlugOffset(node, nodeConfig, connection);
}

export function plugOffset(node, nodeConfig, connection, inputFieldComponentCallback) {
  if (!node || !nodeConfig || !connection) { return; }

  switch (connection.type) {
    case 'Flow::LogicConnection':
      return logicPlugOffset(node, nodeConfig, connection);
    case 'Flow::DataConnection':
      return dataPlugOffset(node, nodeConfig, connection, inputFieldComponentCallback);
    default: break;
  }
}

export function findSnapPlug(connection, plugs, config, position, inputFieldComponentCallback) {
  const snapPlugs = _(plugs).map((plug) => {
    if (plug.state !== 'active') { return; }
    const nodeConfig = getNodeConfig(config, plug.node);
    const offset = plugOffset(plug.node, nodeConfig, {
      ...connection,
      [connection.leftId ? 'rightId' : 'leftId']: plug.node.id,
      [connection.leftId ? 'rightKey' : 'leftKey']: plug.key,
    }, inputFieldComponentCallback);
    return {
      plug,
      position: addPoints({ x: plug.node.positionX, y: plug.node.positionY }, offset),
    };
  }).compact().value();

  return _.find(snapPlugs, ({ position: point }) => isPointInsideCircle(position, point, 30));
}
