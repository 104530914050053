import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connectionPropType, nodePropType } from '../../../util/flow/types';
import { connectionType, connectionDataType } from '../../../util/flow/connection';
import { plugOffset, findSnapPlug } from '../../../util/flow/plug/ui';
import { addPoints, pointDistanceSquared } from '../../../util/flow/ui/point';
import { colorFromDataType } from '../../../util/flow/ui/color';
import ConnectionLine from './ConnectionLine';
import Theme from '../util/theme';
import { useNodeConfig, useFlowRefs, useConfig } from '../util/hooks/core';
import { useStage } from '../util/hooks/konva';
import { getViewportPosition } from '../util/hooks/viewport';
import { fieldComponent } from '../Nodes/Inputs/Field';

const MIN_DRAWING_CONNECTION_LENGTH_SQUARED = 30 ** 2;

export default function Connection({
  connection, left, right, isDrawing,
}) {
  const stage = useStage();
  const [position, setPosition] = useState();
  const { plugs } = useFlowRefs();
  const config = useConfig();
  useEffect(() => {
    if (!stage || !isDrawing) { return; }
    setPosition(getViewportPosition(stage));
    stage.on('mousemove.connection', () => {
      const newPosition = getViewportPosition(stage);
      const snapPlug = findSnapPlug(connection, plugs, config, newPosition, fieldComponent);
      setPosition(snapPlug?.position ?? newPosition);
    });
    return () => stage.off('mousemove.connection');
  }, [isDrawing, stage, setPosition, plugs, config, connection]);

  const leftNodeConfig = useNodeConfig(left);
  const rightNodeConfig = useNodeConfig(right);
  const leftNodePosition = left ? { x: left?.positionX, y: left?.positionY } : null;
  const rightNodePosition = right ? { x: right?.positionX, y: right?.positionY } : null;
  const leftOffset = plugOffset(left, leftNodeConfig, connection, fieldComponent);
  const rightOffset = plugOffset(right, rightNodeConfig, connection, fieldComponent);
  if (!isDrawing
    && (!leftNodePosition || !rightNodePosition || !leftOffset || !rightOffset)) { return null; }

  const leftPosition = !isDrawing || left ? addPoints(leftNodePosition, leftOffset) : position;
  const rightPosition = !isDrawing || right ? addPoints(rightNodePosition, rightOffset) : position;
  if (!leftPosition || !rightPosition) { return null; }
  if (
    isDrawing
    && pointDistanceSquared(leftPosition, rightPosition) < MIN_DRAWING_CONNECTION_LENGTH_SQUARED
  ) { return null; }

  return (
    <ConnectionLine
      leftPosition={addPoints(leftPosition, { x: 22, y: 15 })}
      rightPosition={addPoints(rightPosition, { x: 8, y: 15 })}
      color={connectionType(connection.type) === 'logic' ? Theme.COLOR_ACTIVE : colorFromDataType(...connectionDataType(connection, leftNodeConfig, rightNodeConfig, left, right))}
    />
  );
}

Connection.propTypes = {
  connection: connectionPropType.isRequired,
  left: nodePropType,
  right: nodePropType,
  isDrawing: PropTypes.bool,
};

Connection.defaultProps = {
  isDrawing: false,
  left: null,
  right: null,
};
