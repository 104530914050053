import React from 'react';
import PropTypes from 'prop-types';
import { positionPropTypes, typedFieldValuePropTypes } from '../../../../../../util/flow/types';
import Select from '../../SelectField/Select';

function operators(typedField) {
  switch (typedField?.className) {
    case 'TypedField::Integer': return ['=', '<', '>', 'is not null'];
    case 'TypedField::DateTime': return ['=', '<', '>', 'is not null'];
    case 'TypedField::Text': return ['=', '<', '>', 'is not null'];
    case 'TypedField::Float': return ['=', '<', '>', 'is not null'];
    case 'TypedField::Timestamp': return ['=', '<', '>', 'is not null'];
    default: return ['=', 'is not null'];
  }
}

export default function OperatorSelect({
  x, y, typedField, value, editable, onChange, onInteractionStateChange,
}) {
  const options = operators(typedField).map((operator) => ({ label: operator, value: operator }));

  return (
    <Select
      x={x}
      y={y}
      label={value || 'Operator'}
      options={options}
      selectedValue={value}
      editable={editable}
      onChange={onChange}
      onInteractionStateChange={onInteractionStateChange}
    />
  );
}

OperatorSelect.propTypes = {
  ...positionPropTypes,
  typedField: typedFieldValuePropTypes,
  value: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onInteractionStateChange: PropTypes.func.isRequired,
};

OperatorSelect.defaultProps = {
  typedField: null,
  value: null,
};
