import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['contentsChoices', 'selectAll'];

  connect() {
    setTimeout(() => this.updateChoices(this.selectAllTarget), 200);
  }

  toggleSelectAll({ target }) {
    this.updateChoices(target);
  }

  updateChoices(selectAllElem) {
    if (selectAllElem.checked) {
      this.contentsChoices.removeActiveItems();
      this.contentsChoices.disable();
    } else {
      this.contentsChoices.enable();
    }
  }

  get contentsChoices() {
    return this.application.getControllerForElementAndIdentifier(this.contentsChoicesTarget, 'init--choices')?.choices;
  }
}
