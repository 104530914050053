import { useRef, useEffect, useMemo } from 'react';
import _ from 'lodash';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useActiveElementRef({ lockViewport }) {
  const id = useMemo(() => _.uniqueId(), []);
  return useMemo(() => ({ id, lockViewport }), [id, lockViewport]);
}

export default usePrevious;
