import _ from 'lodash';
import { CONNECTION_MARGIN, CONNECTION_HEIGHT } from '../../../components/Flow/util/theme';

function fieldHeight(input, node, fieldComponentCallback) {
  const component = fieldComponentCallback(input);
  if (component && typeof component.height === 'function') {
    return component.height(input, node);
  }
  return CONNECTION_HEIGHT + CONNECTION_MARGIN;
}

export function inputHeight(input, node, fieldComponentCallback) {
  return fieldHeight(input, node, fieldComponentCallback);
}

export function inputsHeight(nodeConfig, node, fieldComponentCallback) {
  const height = _(nodeConfig.inputs)
    .map((input) => inputHeight(input, node, fieldComponentCallback)).sum() || 0;
  if (height <= 0) { return height; }
  return height - CONNECTION_MARGIN;
}

export function inputY(input, config, node, fieldComponentCallback) {
  return _(config.inputs || [])
    .takeWhile((i) => i.name !== input.name)
    .sumBy((i) => inputHeight(i, node, fieldComponentCallback));
}
