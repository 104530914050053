export function konvaNodePosition(konvaNode) {
  return konvaNode.absolutePosition();
}

export function pointDistanceSquared(a, b) {
  return (a.x - b.x) ** 2 + (a.y - b.y) ** 2;
}

export function isPointInsideCircle(point, center, radius) {
  return pointDistanceSquared(point, center) < radius ** 2;
}

export function addPoints(a, b) {
  return { x: a.x + b.x, y: a.y + b.y };
}

export function centerPoint(origin, width, height) {
  return addPoints(origin, { x: width / 2, y: height / 2 });
}

export function subtractPoints(a, b) {
  return { x: a.x - b.x, y: a.y - b.y };
}

export function multiplyPoints(a, b) {
  return { x: a.x * b.x, y: a.y * b.y };
}

export function dividePoints(a, b) {
  return { x: a.x / b.x, y: a.y / b.y };
}
