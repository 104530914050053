import React from 'react';
import Connection from './Connections/Connection';
import { useDrawing, useNode } from './util/hooks/core';

export default function Drawing() {
  const drawing = useDrawing();
  const leftNode = useNode(drawing.connection?.leftId);
  const rightNode = useNode(drawing.connection?.rightId);
  const connection = drawing.connection ? (
    <Connection
      connection={drawing.connection}
      left={leftNode}
      right={rightNode}
      isDrawing
    />
  ) : null;

  return (
    <>
      {connection}
    </>
  );
}
