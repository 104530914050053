import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useCoreContext } from '../Context';
import { createPermission, destroyPermission } from '../util/actions';

export function controlId(name, kind) {
  return `${_.snakeCase(name)}_${kind}`;
}

export default function Control({
  className, name, kind, disabled, checked, onChange, loading,
}) {
  const { permissions, dispatch } = useCoreContext();
  const [stateLoading, setStateLoading] = useState(false);

  const permission = _.find(permissions, { name, kind });
  const id = controlId(name, kind);

  const onPermissionToggled = async () => {
    setStateLoading(true);
    const promise = permission
      ? destroyPermission(dispatch, permission)
      : createPermission(dispatch, { name, kind });
    await promise;
    setStateLoading(false);
  };

  const controlLoading = _.isNil(loading) ? stateLoading : loading;

  return (
    <div className={`mi ${className}`} id={id}>
      {
        controlLoading ? (
          <i className="fa fa-sync-alt fa-spin" />
        ) : (
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`checkbox_${id}`}
              checked={_.isNil(checked) ? !!permission : checked}
              disabled={disabled}
              onChange={onChange || onPermissionToggled}
            />
            <label className="custom-control-label" htmlFor={`checkbox_${id}`} />
          </div>
        )
      }
    </div>
  );
}

Control.defaultProps = {
  className: '',
  disabled: false,
  checked: null,
  loading: null,
  onChange: null,
};

Control.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};
