import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Rect } from 'react-konva';
import _ from 'lodash';
import Theme from '../../../util/theme';
import PlugLabel from '../../Plug/PlugLabel';

function cornerRadius(index, total) {
  if (total === 1) { return 4; }
  if (index === 0) { return [4, 4, 0, 0]; }
  if (index === total - 1) { return [0, 0, 4, 4]; }
  return 0;
}

function background(index, total) {
  return (
    <Rect
      x={1}
      y={1 + index * 28}
      width={156}
      height={28}
      cornerRadius={cornerRadius(index, total)}
      fill={Theme.COLOR_ACTIVE}
    />
  );
}

export const valueTypes = [PropTypes.string, PropTypes.number, PropTypes.any];

export default function DropdownGroup({
  group, groups, grouped, index: groupIndex,
  value, activeIndex, setActiveIndex, onSelect,
}) {
  const prevNumElements = _(groups).slice(0, groupIndex).sumBy((g) => grouped[g].length);
  const groupTop = 1 + (groupIndex + prevNumElements) * 28;
  return (
    <>
      {group && (
        <PlugLabel
          x={8}
          y={groupTop}
          width={148}
          height={28}
          text={group}
          align="left"
          listening={false}
        />
      )}
      {grouped[group].map((option, index) => (
        <Fragment
          key={_.isObjectLike(option.value) ? JSON.stringify(option.value) : option.value}
        >
          {_.isEqual(option.value, value) || activeIndex === prevNumElements + index
            ? background(
              prevNumElements + index + groupIndex + (group ? 1 : 0),
              _.values(grouped).flat().length + groups.length,
            )
            : null}
          <PlugLabel
            x={18}
            y={groupTop + (index + (group ? 1 : 0)) * 28}
            width={138}
            height={28}
            text={option.label}
            align="left"
            listening
            onMouseOver={() => setActiveIndex(prevNumElements + index)}
            onMouseLeave={() => activeIndex === prevNumElements + index && setActiveIndex(-1)}
            onClick={() => onSelect(option.value)}
          />
        </Fragment>
      ))}
    </>
  );
}

DropdownGroup.defaultProps = {
  group: undefined,
  activeIndex: null,
  value: null,
};

DropdownGroup.propTypes = {
  group: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  grouped: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.oneOfType(valueTypes),
  index: PropTypes.number.isRequired,
  activeIndex: PropTypes.number,
  setActiveIndex: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};
