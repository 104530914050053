import { Controller } from 'stimulus';
import Chartist from 'chartist';
import 'chartist-plugin-tooltips';

export default class extends Controller {
  connect() {
    const data = JSON.parse(this.data.get('data'));
    this.chart = new Chartist.Line(this.element, {
      labels: JSON.parse(this.data.get('labels')),
      series: [data.series],
    }, {
      low: 0,
      showArea: true,
      fullWidth: true,
      fullHeight: true,
      chartPadding: {
        right: 40,
        left: 5,
      },
      plugins: [Chartist.plugins.tooltip({
        appendToBody: true,
        transformTooltipTextFnc: (text) => {
          const index = data.series.findIndex((amount) => amount.toString() === text);
          return data.tooltips[index];
        },
      })],
    });
  }
}
