import { Controller } from 'stimulus';
import _ from 'lodash';

export default class extends Controller {
  static targets = ['button'];

  connect() {
    this.initialData = this.data;
    this.buttonTarget.disabled = true;
    this.inputs.on('keyup.disable_until_change', _.debounce(() => this.change(), 200));
  }

  disconnect() {
    this.inputs.off('keyup.disable_until_change');
  }

  change(e) {
    if (this.forceEnabled) { return; }

    if (e?.data?.force) {
      this.forceEnabled = true;
      this.buttonTarget.disabled = false;
      return;
    }

    this.buttonTarget.disabled = this.data === this.initialData;
  }

  get data() { return $(this.element).serialize(); }

  get inputs() { return $(this.element).find('input'); }
}
