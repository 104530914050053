import { Controller } from 'stimulus';
import _ from 'lodash';

export default class extends Controller {
  static targets=['block', 'button']

  select(e) {
    const classList = this.selectedBlockElem?.classList;
    if (classList) { classList.remove('active'); }
    e.currentTarget.classList.add('active');

    this.buttonTarget.disabled = !this.selectedBlock;
  }

  add() {
    const controller = this.application.getControllerForElementAndIdentifier($('.new_migration,.edit_migration')[0], 'migration--form');
    controller.addBlock(this.selectedBlock);
    $('#modal').modal('hide');
  }

  get selectedBlockElem() {
    return _.find(this.blockTargets, (e) => e.classList.contains('active'));
  }

  get selectedBlock() {
    return this.selectedBlockElem?.dataset;
  }
}
