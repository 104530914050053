import React, { useRef, useState, useMemo } from 'react';
import _ from 'lodash';
import { Stage, Layer } from 'react-konva';
import { nodeSpecializationsConfigPropType } from '../../../util/flow/types';
import Node, { nodeHeight } from '../Nodes/Node';
import { ConfigContext } from '../util/contexts';
import NodePreviewNavigator from './Navigator';
import { useScreenshot } from '../util/hooks/konva';

const SCALE = 0.75;

export default function NodePreview({ config }) {
  const stageRef = useRef();
  const [typeSpecializationIndex, setTypeSpecializationIndex] = useState(0);

  const typeSpecializations = useMemo(() => _.without(Object.keys(config), 'className', 'nodeName'), [config]);
  const specializedConfig = config[typeSpecializations[typeSpecializationIndex]];

  const node = useMemo(() => ({
    className: config.className,
    name: config.nodeName,
    typeSpecialization: typeSpecializations[typeSpecializationIndex],
    positionX: 40,
    positionY: 0,
    input: {},
    editable: false,
  }), [config.className, config.nodeName, typeSpecializations, typeSpecializationIndex]);

  const image = useScreenshot(stageRef, [node]);
  const onDragStart = (event) => {
    event.nativeEvent.dataTransfer.setDragImage(image, 0, 0);
    event.nativeEvent.dataTransfer.setData('text/plain', JSON.stringify(node));
  };

  return (
    <>
      <NodePreviewNavigator
        index={typeSpecializationIndex}
        total={typeSpecializations.length}
        onNav={(index) => setTypeSpecializationIndex(index)}
      />
      <div className="si-preview">
        <div draggable onDragStart={onDragStart} style={{ cursor: 'move' }}>
          <Stage
            width={360}
            height={SCALE * nodeHeight(specializedConfig)}
            scale={{ x: SCALE, y: SCALE }}
            ref={stageRef}
            listening={false}
          >
            <Layer>
              <ConfigContext.Provider value={{ previewMode: true, nodes: [config] }}>
                <Node node={node} connections={[]} />
              </ConfigContext.Provider>
            </Layer>
          </Stage>
        </div>
      </div>
    </>
  );
}

NodePreview.propTypes = {
  config: nodeSpecializationsConfigPropType.isRequired,
};
