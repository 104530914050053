const Theme = {
  COLOR_PRIMARY_BG: '#2F333D',
  COLOR_SCEONDARY_BG: '#353B45',
  COLOR_TERTIARY_BG: '#25272E',
  COLOR_ACTIVE: '#4559BE',
  COLOR_ACCESSORY: '#63262E',
};

export const CONNECTION_HEIGHT = 30;
export const CONNECTION_MARGIN = 10;
export const PLUG_LABEL_STYLE = {
  width: 130,
  lineHeight: 19,
  fontSize: 13,
  fontFamily: 'Roboto',
};

export default Theme;
