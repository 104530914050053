import { Controller } from 'stimulus';
import { triggerEvent } from '../../app/event';

export default class extends Controller {
  static targets = ['label', 'field', 'icon'];

  select(e) {
    this.label = e.target.innerHTML;
    this.value = e.target.getAttribute('data-value');
    triggerEvent(this.fieldTarget.form, 'change');
  }

  set label(label) {
    this.labelTarget.innerHTML = label;
  }

  set value(value) {
    this.iconTarget.classList.remove(this.fieldTarget.value);
    this.fieldTarget.value = value;
    this.iconTarget.classList.add(value);
  }
}
