import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { positionPropTypes, typedFieldValuePropTypes } from '../../../../../../util/flow/types';
import Select from '../../SelectField/Select';

export const typedFieldsPropTypes = PropTypes.arrayOf(typedFieldValuePropTypes);

export default function TypedFieldSelect({
  x, y, typedFields, value, editable, onChange, onInteractionStateChange,
}) {
  const options = typedFields.map(
    (tf) => ({ label: tf.name, value: { name: tf.name, className: tf.className } }),
  );
  const label = _.find(typedFields, value ?? { name: null })?.name ?? 'Typed Field';

  return (
    <Select
      x={x}
      y={y}
      label={label}
      options={options}
      editable={editable}
      selectedValue={value}
      onChange={onChange}
      onInteractionStateChange={onInteractionStateChange}
    />
  );
}

TypedFieldSelect.propTypes = {
  ...positionPropTypes,
  typedFields: typedFieldsPropTypes.isRequired,
  value: typedFieldValuePropTypes,
  onChange: PropTypes.func.isRequired,
  onInteractionStateChange: PropTypes.func.isRequired,
};

TypedFieldSelect.defaultProps = {
  value: null,
};
