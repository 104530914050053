import PropTypes from 'prop-types';

export const typedFieldType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  globalId: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
});

export const templateType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  globalId: PropTypes.string.isRequired,
  typedFields: PropTypes.arrayOf(typedFieldType).isRequired,
});

export const permissionType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired,
  nameExtra: PropTypes.string,
});
