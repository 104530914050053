import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { typedFieldType, permissionType } from '../../../../util/permissions';
import Control from '../Control';
import { destroyPermissions, createPermissions } from '../../util/actions';

export default function TypedField({
  typedField, disabled, permissions, kinds, dispatch,
}) {
  const [loading, setLoading] = useState(false);

  const toggle = async () => {
    setLoading(true);

    if (permissions.length) {
      // Destroy
      await destroyPermissions(dispatch, permissions);
      return setLoading(false);
    }

    // Create
    await createPermissions(dispatch, kinds.map((kind) => ({ kind, name: typedField.globalId })));
    setLoading(false);
  };

  return (
    <li className="permission-item">
      <div className="permissions-row-grid">
        <div className="mi">
          <span>{typedField.name}</span>
        </div>
        <Control
          name={typedField.globalId}
          kind="entity_read"
          loading={loading}
          disabled={disabled || typedField.required}
          checked={!!permissions.length}
          onChange={toggle}
        />
      </div>
    </li>
  );
}

TypedField.propTypes = {
  typedField: typedFieldType.isRequired,
  disabled: PropTypes.bool.isRequired,
  permissions: PropTypes.arrayOf(permissionType).isRequired,
  kinds: PropTypes.arrayOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
};
