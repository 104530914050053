import React from 'react';
import PropTypes from 'prop-types';
import Control from '../Control';
import Migration from './Migration';

const FEATURES = [
  'Plugin::Nova::Transaction',
  'Plugin::Wallee::Transaction',
  'Template',
  'Resource',
  'Flow::Scene',
];

function Feature({ feature }) {
  return (
    <li className="permission-item">
      <div className="permissions-row-grid">
        <div className="mi">
          <span>{I18n.t(`permissions.feature_permission.${feature}`)}</span>
        </div>
        <Control name={feature} kind="entity_read" />
      </div>
    </li>
  );
}

Feature.propTypes = {
  feature: PropTypes.string.isRequired,
};

export default function Features() {
  return (
    <div className="tab-pane" id="permission--content-features" role="tabpanel" aria-labelledby="permission--content-features">
      <div className="datagrid datagrid-sticky">

        <div className="datagrid-header">
          <div className="permissions-header">
            <div className="permissions-row-grid">
              <span>
                {I18n.t('permissions.features.description')}
              </span>
              <span>
                {I18n.t('permissions.features.access')}
              </span>
            </div>
          </div>
        </div>
        <div className="datagrid-body">
          <div className="permissions-list">
            <div className="permission-category">
              <div className="permission-category-body">
                <ul>
                  {FEATURES.map((feature) => <Feature key={feature} feature={feature} />)}
                  <Migration />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
