import { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import humps from 'lodash-humps';
import { useImmerReducer } from 'use-immer';
import reducer from '../reducer';
import { sceneParams } from '../../../../util/flow/params';
import {
  DispatchContext, SceneContext, DrawingContext, ConfigContext, RefsContext,
} from '../contexts';
import { usePrevious } from './misc';
import { getNodeConfig } from '../../../../util/flow/node';

export function useFlowState(initialScene) {
  const [{ scene, drawing, refs }, dispatch] = useImmerReducer(
    reducer,
    { scene: initialScene, drawing: {}, refs: {} },
  );
  const prevScene = usePrevious(scene);
  const [currentSceneParams, setCurrentSceneParams] = useState(sceneParams(scene));

  useEffect(() => {
    const data = sceneParams(scene);
    if (_.isEqual(data, currentSceneParams) || _.isEqual(scene, prevScene)) { return; }
    if (scene.intermediate) { return; }

    $.ajax(`/flow/scenes/${scene.id}.json`, {
      method: 'put',
      contentType: 'application/json',
      data: JSON.stringify(data),
    }).done((response) => {
      console.log('updated scene', response); // eslint-disable-line no-console
      const updatedScene = humps(response);
      setCurrentSceneParams(sceneParams(updatedScene));
      dispatch({ type: 'update-scene', scene: updatedScene });
    }).fail((e) => {
      if (e.statusText === 'abort') { return; } // Cancelled
      console.log('failed to update scene', e); // eslint-disable-line no-console
    });
  }, [scene, currentSceneParams, setCurrentSceneParams, dispatch, prevScene]);

  return [{ scene, drawing, refs }, dispatch];
}

export function useFlowRefs() {
  return useContext(RefsContext);
}

export function useDrawing() {
  return useContext(DrawingContext);
}

export function useScene() {
  return useContext(SceneContext);
}

export function useDispatch() {
  return useContext(DispatchContext);
}

export function useNode(id) {
  const scene = useScene();
  return _.find(scene?.nodes, { id });
}

export function useConfig() {
  return useContext(ConfigContext);
}

export function useNodeConfig(node) {
  const config = useConfig();
  return getNodeConfig(config, node);
}


export default useFlowState;
