import React from 'react';
import _ from 'lodash';
import { useCoreContext } from '../../Context';
import Template from './Template';

export function isTemplateEnabled(permissions) {
  return _.find(permissions, { name: 'Template', kind: 'entity_read' });
}

export default function Templates() {
  const { templates } = useCoreContext();

  return (
    <div className="tab-pane" id="permission--content-templates" role="tabpanel" aria-labelledby="permission--tab-templates">
      <div className="datagrid datagrid-sticky">

        <div className="datagrid-header">
          <div className="permissions-header">
            <div className="permissions-row-grid">
              <span>
                {I18n.t('permissions.templates.description')}
              </span>
              <span>
                {I18n.t('permissions.templates.access')}
              </span>
            </div>
          </div>
        </div>
        <div className="datagrid-body">
          <div className="permissions-list">
            <div className="permission-category">
              <div className="permission-category-body">
                <ul>
                  {templates.map((template) => <Template key={template.id} template={template} />)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
