import React from 'react';
import PropTypes from 'prop-types';
import Body from './Body';
import Header from './Header';
import { permissionType, templateType } from '../../util/permissions';
import { CoreProvider } from './Context';

export default function Permissions({
  permissions, templates, parentUrl, parentTitle,
}) {
  return (
    <div className="page page-fixed-header">
      <CoreProvider permissions={permissions} templates={templates}>
        <Header parentUrl={parentUrl} parentTitle={parentTitle} />
        <Body />
      </CoreProvider>
    </div>
  );
}

Permissions.propTypes = {
  permissions: PropTypes.arrayOf(permissionType).isRequired,
  templates: PropTypes.arrayOf(templateType).isRequired,
  parentTitle: PropTypes.string.isRequired,
  parentUrl: PropTypes.string.isRequired,
};
