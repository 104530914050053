import { Controller } from 'stimulus';

export const SIDEBAR_ACTIVE_CLASS = 'ml-sidebar-active';

export default class extends Controller {
  // Events
  show(e) {
    e.preventDefault();
    this.element.classList.add(SIDEBAR_ACTIVE_CLASS);
  }

  hide(e) {
    e.preventDefault();
    this.element.classList.remove(SIDEBAR_ACTIVE_CLASS);
  }
}
