import React from 'react';
import PropTypes from 'prop-types';
import { isTemplateEnabled } from '../Body/Templates';
import { useCoreContext } from '../Context';
import Tab from './Tab';

export default function Header({ parentUrl, parentTitle }) {
  const { permissions } = useCoreContext();
  return (
    <div className="page-header">
      <div className="page-icon">
        <i className="fa fa-user-lock" />
      </div>
      <div className="page-breadcrumb">
        <ul className="breadcrumb">
          <li><a href={parentUrl}>{parentTitle}</a></li>
          <li className="active">{I18n.t('permissions.index.title')}</li>
        </ul>
      </div>
      <div className="page-tabs">
        <ul className="nav nav-tabs" id="permissionTabs" role="tablist">
          <Tab type="contents" active />
          {isTemplateEnabled(permissions) && <Tab type="templates" />}
          <Tab type="features" />
        </ul>
      </div>
    </div>
  );
}

Header.propTypes = {
  parentTitle: PropTypes.string.isRequired,
  parentUrl: PropTypes.string.isRequired,
};
