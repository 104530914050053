import React from 'react';
import Features from './Features';
import Contents from './Contents';
import Templates, { isTemplateEnabled } from './Templates';
import { useCoreContext } from '../Context';

export default function Body() {
  const { permissions } = useCoreContext();
  return (
    <div className="page-body">
      <div className="tab-content" id="tabContent_permissions">
        <Contents />
        {isTemplateEnabled(permissions) && <Templates />}
        <Features />
      </div>
    </div>
  );
}
