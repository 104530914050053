function addConnection(draft, plug) {
  const { connection } = draft.drawing;
  draft.drawing.connection = null;
  if (plug?.state !== 'active') { return; }

  draft.scene.connections.push({
    ...connection,
    leftKey: connection.leftKey ?? plug.key,
    rightKey: plug.kind === 'logic' ? null : connection.rightKey ?? plug.key,
    leftId: connection.leftId ?? plug.node.id,
    rightId: connection.rightId ?? plug.node.id,
  });
}

export default function reducer(draft, action) {
  switch (action.type) {
    case 'draw-action-plug-start':
      draft.drawing.connection = {
        type: action.kind === 'logic' ? 'Flow::LogicConnection' : 'Flow::DataConnection',
        leftId: action.direction === 'out' ? action.node.id : null,
        rightId: action.direction === 'in' ? action.node.id : null,
        leftKey: action.direction === 'out' ? action.key : null,
        rightKey: action.direction === 'in' ? action.key : null,
      };
      break;
    case 'draw-action-plug-end':
      addConnection(draft, action);
      break;
    case 'draw-action-end':
      addConnection(draft, action.plug);
      break;
    default: break;
  }
}
