import { useState, useEffect, useCallback } from 'react';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { subtractPoints, multiplyPoints } from '../../../../util/flow/ui/point';

const DEFAULT_STAGE_SIZE = 2000;
const SCALE_SPEED = 1.1;
export const MAX_SCALE = 2;
export const MIN_SCALE = 0.33;

function fit(containerRef, updateFrame) {
  const container = containerRef.current;
  if (!container) { return; }

  updateFrame((draft) => {
    draft.width = container.offsetWidth;
    draft.height = container.offsetHeight;
  });
}

function useFittableStage(fitStage) {
  useEffect(() => fitStage(), [fitStage]);

  useEffect(() => {
    window.addEventListener('resize', fitStage);
    return () => window.removeEventListener('resize', fitStage);
  }, [fitStage]);
}

function useDraggableStage(updateFrame) {
  return useCallback((e) => {
    const stage = e.target.getStage();
    updateFrame((draft) => {
      draft.x = stage.x();
      draft.y = stage.y();
    });
  }, [updateFrame]);
}

function useScrollableStage(updateFrame, setScale) {
  return useCallback((e) => {
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const invertFactor = !e.evt.webkitDirectionInvertedFromDevice ? -1 : 1;

    // Scroll horizontally
    const isScrollEvent = Math.abs(e.evt.deltaX) >= Math.abs(e.evt.deltaY);
    if (isScrollEvent) {
      e.evt.preventDefault();
      return updateFrame((draft) => {
        draft.x += e.evt.deltaX * oldScale * invertFactor;
      });
    }

    // Scale
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    let newScale = oldScale * (e.evt.deltaY * invertFactor > 0 ? SCALE_SPEED : 1 / SCALE_SPEED);
    newScale = Math.max(Math.min(MAX_SCALE, newScale), MIN_SCALE);

    setScale({ x: newScale, y: newScale });
    updateFrame((draft) => {
      draft.x = -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale;
      draft.y = -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale;
    });
  }, [setScale, updateFrame]);
}

export function useViewport(containerRef) {
  const [scale, setScale] = useState({ x: 1, y: 1 });
  const [frame, updateFrame] = useImmer({
    x: 0,
    y: 0,
    width: containerRef?.current?.offsetWidth ?? DEFAULT_STAGE_SIZE,
    height: containerRef?.current?.offsetHeight ?? DEFAULT_STAGE_SIZE,
  });

  // Fit stage initially from container and on subsequent resizes
  const fitStage = useCallback(() => fit(containerRef, updateFrame), [containerRef, updateFrame]);
  useFittableStage(fitStage);

  const onScroll = useScrollableStage(updateFrame, setScale);
  const onDrag = useDraggableStage(updateFrame);
  const setViewport = useCallback(({ origin: newOrigin, scale: newScale }) => {
    if (newOrigin) {
      updateFrame((draft) => {
        draft.x = newOrigin.x;
        draft.y = newOrigin.y;
      });
    }

    if (_.isNumber(newScale)) {
      setScale({ x: newScale, y: newScale });
    } else if (_.isNumber(newScale.x) && _.isNumber(newScale.y)) {
      setScale(newScale);
    }
  }, [updateFrame, setScale]);

  return [frame, scale, { onScroll, onDrag, setViewport }];
}

export function transformPositionToViewport(
  stage, position, offset = { x: 7.616974973, y: 15.233949946 },
) {
  const scaledOffset = multiplyPoints(offset, stage.getAbsoluteScale());
  const pos = subtractPoints(position, scaledOffset);
  const transform = stage.getAbsoluteTransform().copy();
  transform.invert();
  return transform.point(pos);
}

export function getViewportPosition(stage) {
  const position = stage.getPointerPosition();
  return transformPositionToViewport(stage, position);
}

export default useViewport;
