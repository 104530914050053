import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import produce from 'immer';
import { editableFieldPropTypes } from '../types';
import ComparatorField from './ComparatorField';
import { CONNECTION_MARGIN } from '../../../util/theme';
import { useDispatch, useNodeConfig } from '../../../util/hooks/core';
import { getNodeInputValue } from '../../../../../util/flow/node';
import { Plus, Minus } from '../../../util/Images';

function valuesFromNode(node, input) {
  const value = getNodeInputValue(node, input);
  if (_.isEmpty(value)) { return [{}]; }
  return value;
}

const deafultHash = {
  operator: '=',
  joinOperator: 'AND',
};

function fields({
  x, y, values, activeIndex, nodeConfig, templateId, updateNode,
  onButtonAction, input, node, connections, setActiveIndex, inputFieldComponentCallback,
}) {
  return _(values)
    .map((value, index) => [value, index])
    .sortBy(([, indexA]) => (indexA === activeIndex ? 1 : 0))
    .map(([value, index], _arrayIndex, array) => {
      const top = _(array)
        .filter(([_val, i]) => i < index)
        .sumBy(([val]) => ComparatorField.height(val));
      return (
        <ComparatorField
          key={index} // eslint-disable-line react/no-array-index-key
          x={x}
          y={y + top}
          typedFields={_.filter(_.find(nodeConfig.selectors, { name: 'typed_field' }).values, { templateId })}
          value={value}
          onChange={(changed, intermediate = false) => updateNode(changed, index, intermediate)}
          buttonImageComponent={index !== values.length - 1 ? Minus : Plus}
          onButtonAction={() => onButtonAction(index)}
          keyPrefix={`${input.name}.${index}`}
          node={node}
          connections={_.filter(connections, (conn) => _.startsWith(conn.rightKey, `${input.name}.${index}`))}
          onInteractionStateChange={(active) => (
            active ? setActiveIndex(index) : activeIndex === index && setActiveIndex(null)
          )}
          inputFieldComponentCallback={inputFieldComponentCallback}
        />
      );
    })
    .value();
}

export default function ComparatorHashBuilderField({
  x, y, input, node, connections, onInteractionStateChange, inputFieldComponentCallback,
}) {
  const dispatch = useDispatch();
  const inputKey = _.camelCase(input.name);
  const values = valuesFromNode(node, input);
  const nodeConfig = useNodeConfig(node);

  const [activeIndex, setActiveIndex] = useState(null);
  useEffect(() => {
    onInteractionStateChange(_.isNumber(activeIndex));
  }, [activeIndex, onInteractionStateChange]);

  const updateNode = useCallback((value, index, intermediate) => dispatch({
    type: `update-node${intermediate ? '-intermediate' : ''}`,
    node: produce(node, (draft) => {
      if (!draft.input[inputKey] || _.isEmpty(draft.input[inputKey])) {
        draft.input[inputKey] = [{}];
      }
      if (value) {
        draft.input[inputKey][index] = value;
      } else {
        draft.input[inputKey].splice(index, 1);
      }
    }),
  }), [dispatch, node, inputKey]);

  const onButtonAction = useCallback((index) => {
    // Remove value
    if (index < values.length - 1) { return updateNode(null, index); }

    // Add value at end
    updateNode(deafultHash, index + 1);
  }, [updateNode, values]);

  const templates = _.find(nodeConfig.selectors, { name: 'template' }).values;
  const templateId = _.find(templates, { name: node.input?.templateName })?.id;

  return (
    <>
      {fields({
        x,
        y,
        values,
        activeIndex,
        nodeConfig,
        templateId,
        updateNode,
        onButtonAction,
        input,
        node,
        connections,
        setActiveIndex,
        inputFieldComponentCallback,
      })}
    </>
  );
}

ComparatorHashBuilderField.propTypes = {
  ...editableFieldPropTypes,
  inputFieldComponentCallback: PropTypes.func.isRequired,
};

ComparatorHashBuilderField.height = (input, node) => (CONNECTION_MARGIN
  + _(valuesFromNode(node, input)).map((value) => ComparatorField.height(value)).sum()
);

ComparatorHashBuilderField.hasPlug = () => false;
