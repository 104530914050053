import React from 'react';
import PropTypes from 'prop-types';
import { configPropType, drawingPropType, scenePropType } from '../../../util/flow/types';
import {
  StageContext, ConfigContext, DispatchContext, DrawingContext, RefsContext, SceneContext,
} from './contexts';
import { childrenType } from '../../../util/types';

export default function ContextProvider({
  stage, config, dispatch, drawing, scene, refs, children,
}) {
  return (
    <StageContext.Provider value={stage}>
      <ConfigContext.Provider value={config}>
        <DispatchContext.Provider value={dispatch}>
          <DrawingContext.Provider value={drawing}>
            <SceneContext.Provider value={scene}>
              <RefsContext.Provider value={refs}>
                {children}
              </RefsContext.Provider>
            </SceneContext.Provider>
          </DrawingContext.Provider>
        </DispatchContext.Provider>
      </ConfigContext.Provider>
    </StageContext.Provider>
  );
}

ContextProvider.propTypes = {
  stage: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  config: configPropType.isRequired,
  dispatch: PropTypes.func.isRequired,
  drawing: drawingPropType.isRequired,
  scene: scenePropType.isRequired,
  refs: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  children: childrenType.isRequired,
};

ContextProvider.defaultProps = {
  stage: null,
};
