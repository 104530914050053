import _ from 'lodash';

function updateNode(draft, action, intermediate) {
  const index = _.findIndex(draft.scene.nodes, { id: action.node.id });
  draft.scene.nodes[index] = action.node;
  if (intermediate && !draft.scene.intermediate) {
    draft.originalScene = draft.scene;
  }
  draft.scene.intermediate = intermediate;
}

function resetNodeConnections(draft, action) {
  _(draft.scene.connections)
    .filter((con) => con.leftId === action.node.id || con.rightId === action.node.id)
    .each((con) => { con.destroyed = true; }); // eslint-disable-line no-param-reassign
}

export default function reducer(draft, action) {
  switch (action.type) {
    case 'update-node-intermediate':
      return updateNode(draft, action, true);
    case 'update-node':
      return updateNode(draft, action);
    case 'reset-node-connections':
      return resetNodeConnections(draft, action);
    default: break;
  }
}
