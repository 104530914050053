import { Controller } from 'stimulus';

function getFlatpickrController(application, element) {
  return application.getControllerForElementAndIdentifier(element, 'flatpickr');
}

export default class extends Controller {
  static targets = ['minInput', 'maxInput', 'dateEnabledCheckbox', 'timeEnabledCheckbox'];

  updateFlatpickrs() {
    this.updateFlatpickr(this.minInputTarget);
    this.updateFlatpickr(this.maxInputTarget);
  }

  updateFlatpickr(elem) {
    const time = this.timeEnabledCheckboxTarget.checked;
    const date = this.dateEnabledCheckboxTarget.checked;
    elem.dataset.flatpickrAltFormat = this.data.get(`flatpickr-format-${date ? 'date' : ''}${time ? 'time' : ''}`); // eslint-disable-line no-param-reassign
    elem.dataset.flatpickrEnableTime = time; // eslint-disable-line no-param-reassign
    elem.dataset.flatpickrNoCalendar = !date; // eslint-disable-line no-param-reassign

    getFlatpickrController(this.application, elem).disconnect();
    getFlatpickrController(this.application, elem).connect();
  }
}
