import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Rect, Group } from 'react-konva';
import { positionPropTypes, cornerRadiusPropType } from '../../../util/flow/types';
import Theme, { CONNECTION_HEIGHT } from './theme';

export default function Button({
  x, y, visible, fill, cornerRadius, onClick, Image, onMouseEnter, onMouseLeave,
}) {
  const [active, setActive] = useState(false);
  useEffect(() => { if (!visible) setActive(false); }, [visible, setActive]);

  if (!visible) { return null; }
  return (
    <Group
      x={x}
      y={y}
      onClick={onClick}
      onMouseEnter={() => { setActive(true); onMouseEnter(); }}
      onMouseLeave={() => { setActive(false); onMouseLeave(); }}
    >
      <Rect
        x={0}
        y={0}
        cornerRadius={cornerRadius}
        width={CONNECTION_HEIGHT}
        height={CONNECTION_HEIGHT}
        fill={active ? Theme.COLOR_ACTIVE : fill}
      />
      <Image width={30} height={30} />
    </Group>
  );
}
Button.propTypes = {
  ...positionPropTypes,
  visible: PropTypes.bool.isRequired,
  fill: PropTypes.string,
  cornerRadius: cornerRadiusPropType,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  Image: PropTypes.func.isRequired,
};

Button.defaultProps = {
  fill: 'transparent',
  cornerRadius: 0,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};
