import { Controller } from 'stimulus';

function icon() {
  return 'fa fa-exclamation-triangle';
}

function allowAction(element) {
  return !element.dataset.confirmMessage;
}

export default class extends Controller {
  static targets = ['modal', 'icon', 'title', 'message', 'buttonConfirm', 'buttonCancel'];

  action(event) {
    if (allowAction(event.currentTarget)) { return; }

    Rails.stopEverything(event);
    this.showModal(event.currentTarget);
  }

  perform() {
    const element = this.activeElement;
    const originalHtml = element.innerHTML;
    const url = element.href;
    const method = element.dataset.method || 'GET';
    if (element.dataset.disableWith) {
      element.text(element.dataset.disableWith);
    }
    $.ajax({
      url,
      dataType: 'script',
      method: method.toUpperCase(),
    }).always(() => { element.innerHTML = originalHtml; }); // eslint-disable-line no-param-reassign
  }

  showModal(element) {
    const message = element.dataset.confirmMessage;
    const title = element.dataset.title || 'Delete';
    const type = element.dataset.type || 'warning';
    const confirmButtonText = element.dataset.confirmButtonText || 'Delete';
    if (!message) { return true; }

    this.activeElement = element;
    this.iconTarget.className = icon(type);
    this.titleTarget.textContent = title;
    this.messageTarget.textContent = message;
    this.buttonConfirmTarget.textContent = confirmButtonText;
    $(this.modalTarget).modal('show');
  }
}
