import { Controller } from 'stimulus';
import _ from 'lodash';
import consumer from '../../app/consumer';

export default class extends Controller {
  static targets = ['row'];

  connect() {
    this.activeJobs = {};

    const self = this;
    this.subscription = consumer.subscriptions.create({ channel: 'FlowChannel' }, {
      connected() {
        // Refresh active jobs
        self.subscription.perform('active_jobs');
      },
      received(message) {
        switch (message.type) {
          case 'flow_job_start':
            return self.onJobStart(message.data);
          case 'flow_job_finish':
            return self.onJobStop(message.data);
          case 'active_jobs':
            return self.resetActiveJobs(message.data);
          default: break;
        }
      },
    });
  }

  disconnect() {
    this.activeJobs = {};
    this.subscription.unsubscribe();
  }

  resetActiveJobs({ jobs }) {
    this.activeJobs = {};
    jobs.forEach((job) => this.onJobStart(job));
  }

  onJobStart({ scene_id: sceneId, id }) {
    this.activeJobs[sceneId] = this.activeJobs[sceneId] || [];
    if (this.activeJobs[sceneId].includes(id)) { return; }

    this.activeJobs[sceneId].push(id);
    this.updateRowStates();
  }

  onJobStop({ scene_id: sceneId, id }) {
    this.activeJobs[sceneId] = this.activeJobs[sceneId] || [];
    if (!this.activeJobs[sceneId].includes(id)) { return; }

    _.pull(this.activeJobs[sceneId], id);
    this.updateRowStates();
  }

  updateRowStates() {
    _.forEach(this.activeJobs, (uuids, sceneId) => {
      const row = this.rowTargets.find((r) => parseInt(r.dataset.id, 10) === parseInt(sceneId, 10));
      if (!row) { return; }

      $(row)
        .find('.status-label.default-state')
        .toggleClass('hide', uuids.length > 0)
        .end()
        .find('.status-label.running')
        .toggleClass('hide', uuids.length <= 0);
    });
  }
}
