import React from 'react';
import Nodes from './Nodes';
import Connections from './Connections';
import { useScene } from './util/hooks/core';

export default function Scene() {
  const scene = useScene();
  return (
    <>
      <Connections connections={scene.connections} nodes={scene.nodes} />
      <Nodes nodes={scene.nodes} connections={scene.connections} />
    </>
  );
}
