import { Controller } from 'stimulus';
import _ from 'lodash';

const ACTIVE_CLASS = 'filter-active';

export default class extends Controller {
  static targets = ['li', 'filter', 'container'];

  toggle() {
    if (this.containerTarget.classList.contains(ACTIVE_CLASS)) {
      return this.containerTarget.classList.remove(ACTIVE_CLASS);
    }
    this.containerTarget.classList.add(ACTIVE_CLASS);
  }

  updateItems() {
    _.forEach(this.liTargets, (li) => {
      const visible = !_(this.selectedFilters).intersection(JSON.parse(li.dataset.tags)).isEmpty();
      li.classList[visible ? 'remove' : 'add']('hide');
    });

    this.toggle();
  }

  get selectedFilters() {
    const selectedFilters = _(this.filterTargets).filter('checked').map('value').value();
    if (!_.isEmpty(selectedFilters)) { return selectedFilters; }

    return _.map(this.filterTargets, 'value');
  }
}
