export function shouldHandle(event) { // eslint-disable-line import/prefer-default-export
  const path = event.path || event.composedPath();
  if (!path) { return true; }
  const targetIndex = path.indexOf(event.currentTarget);
  const children = path.slice(0, targetIndex);
  return !children.some((e) => e.dataset.noEventBubble !== null
    && e.dataset.noEventBubble !== undefined && e.dataset.noEventBubble !== false);
}

export function roundUpToMultiple(number, multiplier) {
  return Math.ceil(number / multiplier) * multiplier;
}

// Adapted from https://stackoverflow.com/a/26763360
export function getElementPath(elem) {
  const paths = [];

  $(elem).each((_index, element) => {
    let path;
    let $node = jQuery(element);
    while ($node.length) {
      const realNode = $node.get(0);
      let name = realNode.localName;
      if (!name) { break; }

      name = name.toLowerCase();
      const parent = $node.parent();
      const sameTagSiblings = parent.children(name);

      if (sameTagSiblings.length > 1) {
        const allSiblings = parent.children();
        const index = allSiblings.index(realNode) + 1;
        if (index > 0) {
          name += `:nth-child(${index})`;
        }
      }

      path = name + (path ? ` > ${path}` : '');
      $node = parent;
    }

    paths.push(path);
  });

  return paths.join(',');
}

export function useTooltip() {
  return (el) => $(el).tooltip();
}

export function updateInputValue(input, value) {
  // eslint-disable-next-line no-underscore-dangle
  if (input._flatpickr) {
    // eslint-disable-next-line no-underscore-dangle
    return input._flatpickr.setDate(value);
  }
  $(input).val(value);
}
