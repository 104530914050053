import React from 'react';
import PropTypes from 'prop-types';
import { Group, Line, Rect } from 'react-konva';
import { positionPropTypes } from '../../../../util/flow/types';
import { colorFromDataType } from '../../../../util/flow/ui/color';

const statePropType = PropTypes.oneOf(['default', 'active', 'connected', 'drawing']);

function Caret() {
  return <Line points={[-3.405, -6, 3.405, 0, -3.405, 6]} closed fill="white" />;
}

function RectWithRadius({ radius, dataType, ...extraProps }) {
  return (
    <Rect
      x={-radius}
      y={-radius}
      width={radius * 2}
      height={radius * 2}
      cornerRadius={dataType === 'array' ? 2 : radius}
      {...extraProps}
    />
  );
}

RectWithRadius.propTypes = {
  radius: PropTypes.number.isRequired,
  dataType: PropTypes.string.isRequired,
};

function DataIcon({
  state, dataType, secondaryDataType, hasCustomValue, direction,
}) {
  const fill = colorFromDataType(dataType, secondaryDataType);
  const leftPosition = { x: direction === 'in' ? -16 : 8, y: 0 };
  const rightPosition = { x: leftPosition.x + 8, y: 0 };
  switch (state) {
    case 'connected':
      return (
        <>
          <RectWithRadius radius={8} dataType={dataType} fill={fill} />
          <Line
            stroke={fill}
            points={[leftPosition.x, leftPosition.y, rightPosition.x, rightPosition.y]}
            strokeWidth={2}
          />
        </>
      );
    case 'drawing': // Intended pass through
    case 'active':
      return (
        <>
          <RectWithRadius radius={3} dataType={dataType} cornerRadius={3} fill={fill} />
          <RectWithRadius radius={8} dataType={dataType} fill="transparent" stroke={fill} strokeWidth={2} />
        </>
      );
    default:
      if (hasCustomValue) {
        return (
          <>
            <RectWithRadius radius={7} dataType={dataType} fill={fill} opacity={0.25} />
            <RectWithRadius radius={8} dataType={dataType} fill="transparent" stroke={fill} strokeWidth={2} />
          </>
        );
      }
      return (
        <RectWithRadius radius={8} dataType={dataType} fill="transparent" stroke={fill} strokeWidth={2} />
      );
  }
}

DataIcon.propTypes = {
  state: statePropType.isRequired,
  dataType: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['in', 'out']).isRequired,
  secondaryDataType: PropTypes.string,
  hasCustomValue: PropTypes.bool.isRequired,
};

DataIcon.defaultProps = {
  secondaryDataType: null,
};

export default function Icon({
  x, y, type, state, dataType, secondaryDataType, hasCustomValue, direction,
}) {
  let content;
  switch (type) {
    case 'logic':
      content = <Caret />;
      break;
    default:
      content = (
        <DataIcon
          state={state}
          dataType={dataType}
          direction={direction}
          secondaryDataType={secondaryDataType}
          hasCustomValue={hasCustomValue}
        />
      );
  }
  return (
    <Group x={x} y={y}>
      {content}
    </Group>
  );
}

Icon.propTypes = {
  ...positionPropTypes,
  type: PropTypes.oneOf(['logic', 'data']).isRequired,
  state: statePropType.isRequired,
  dataType: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['in', 'out']).isRequired,
  secondaryDataType: PropTypes.string,
  hasCustomValue: PropTypes.bool.isRequired,
};

Icon.defaultProps = {
  secondaryDataType: null,
};
