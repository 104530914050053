import _ from 'lodash';

export default function reducer(draft, action) {
  switch (action.type) {
    case 'register-active-element':
      _.set(draft, ['refs', 'activeElement'], action.element);
      break;
    case 'unregister-active-element':
      if (draft.refs?.activeElement?.id === action.element?.id) {
        _.unset(draft, ['refs', 'activeElement']);
      }
      break;
    default: break;
  }
}
