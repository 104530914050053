import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Group } from 'react-konva';
import { CONNECTION_HEIGHT, CONNECTION_MARGIN } from '../../../../util/theme';
import { positionPropTypes, comparatorValueType } from '../../../../../../util/flow/types';
import EditText from '../../EditText';
import OperatorSelect from './OperatorSelect';
import TypedFieldSelect, { typedFieldsPropTypes } from './TypedFieldSelect';
import Plugs, { plugsPropTypes } from './Plugs';
import JoinOperatorSelect from './JoinOperatorSelect';
import { useDispatch } from '../../../../util/hooks/core';

const componentHeight = CONNECTION_HEIGHT + CONNECTION_MARGIN;

export default function ComparatorField({
  x, y, typedFields, value, onChange, onButtonAction, onInteractionStateChange,
  keyPrefix, node, connections, buttonImageComponent, inputFieldComponentCallback,
}) {
  const dispatch = useDispatch();
  const [activeComponent, setActiveComponent] = useState(null);

  useEffect(() => {
    onInteractionStateChange(!!activeComponent);
  }, [onInteractionStateChange, activeComponent]);

  const selectedTF = _.find(typedFields, value?.typedField);

  const components = {
    join: (
      <JoinOperatorSelect
        key="join-operator-select"
        x={0}
        y={JoinOperatorSelect.positionY(value)}
        value={value?.joinOperator}
        onChange={(joinOperator) => onChange({ ...value, joinOperator })}
      />
    ),
    value: (
      <EditText
        key="value"
        x={0}
        y={2 * componentHeight}
        text={value?.value}
        placeholder="Value"
        editable={!_(connections).map('rightKey').some((k) => _.endsWith(k, 'value'))}
        showIndicator
        onChange={(text) => onChange({ ...value, value: text })}
        onChangeIntermediate={(text) => onChange({ ...value, value: text }, true)}
        cancelIntermediateChanges={() => dispatch({ type: 'cancel-intermediate' })}
      />
    ),
    operator: (
      <OperatorSelect
        key="operator"
        x={0}
        y={componentHeight}
        typedField={selectedTF}
        value={value?.operator}
        editable={!_(connections).map('rightKey').some(k => _.endsWith(k, 'operator'))}
        onChange={(operator) => onChange({ ...value, operator })}
        onInteractionStateChange={(active) => (active ? setActiveComponent('operator') : activeComponent === 'operator' && setActiveComponent(null))}
      />
    ),
    tf: (
      <TypedFieldSelect
        key="tf"
        x={0}
        y={0}
        typedFields={typedFields}
        value={value?.typedField}
        editable={!_(connections).map('rightKey').some(k => _.endsWith(k, 'typed_field'))}
        onChange={(typedField) => onChange({ ...value, typedField })}
        onInteractionStateChange={(active) => (active ? setActiveComponent('tf') : activeComponent === 'tf' && setActiveComponent(null))}
      />
    ),
  };

  const order = _.sortBy(Object.keys(components), (a) => (a === activeComponent ? 1 : 0));

  return (
    <Group
      x={x}
      y={y}
      width={130}
      height={CONNECTION_HEIGHT}
    >
      {order.map((key) => components[key])}
      <Plugs
        node={node}
        connections={connections}
        keyPrefix={keyPrefix}
        typedField={selectedTF}
        buttonImageComponent={buttonImageComponent}
        value={value}
        onButtonAction={onButtonAction}
        inputFieldComponentCallback={inputFieldComponentCallback}
      />
    </Group>
  );
}

ComparatorField.propTypes = {
  ...positionPropTypes,
  typedFields: typedFieldsPropTypes.isRequired,
  value: comparatorValueType,
  onChange: PropTypes.func.isRequired,
  onInteractionStateChange: PropTypes.func.isRequired,
  ...plugsPropTypes,
};

ComparatorField.defaultProps = {
  value: null,
};

ComparatorField.height = (value) => componentHeight * 3 + CONNECTION_MARGIN + EditText.height(value?.value ?? 'Value');
