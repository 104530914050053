import React from 'react';
import { useCoreContext } from '../../Context';
import Content from './Content';

export default function Contents() {
  const { templates } = useCoreContext();

  return (
    <div className="tab-pane show active" id="permission--content-contents" role="tabpanel" aria-labelledby="permission--tab-contents">
      <div className="datagrid datagrid-sticky">

        <div className="datagrid-header">
          <div className="permissions-header">
            <div className="permissions-row-grid">
              <span>
                {I18n.t('permissions.contents.description')}
              </span>
              <span>
                {I18n.t('permissions.contents.read')}
              </span>
              <span>
                {I18n.t('permissions.contents.create')}
              </span>
              <span>
                {I18n.t('permissions.contents.update')}
              </span>
              <span>
                {I18n.t('permissions.contents.delete')}
              </span>
            </div>
          </div>
        </div>
        <div className="datagrid-body">
          <div className="permissions-list accordion">
            {templates.map((template) => <Content key={template.id} template={template} />)}
          </div>
        </div>
      </div>
    </div>
  );
}
