import Flatpickr from 'stimulus-flatpickr';

export default class extends Flatpickr {
  change(selectedDates, dateStr) {
    const $element = $(this.element);
    const url = $element.data('url');
    if (dateStr.length) {
      const params = [];
      $element.closest('.datagrid-datepicker').find('input').each((_, elem) => {
        if (elem.value.length > 0) {
          params.push(`${encodeURIComponent(elem.name)}=${encodeURIComponent(elem.value)}`);
        }
      });
      const char = url.indexOf('?') > -1 ? '&' : '?';
      Turbolinks.visit(`${url}${char}${params.join('&')}&date_filter=t`);
    } else {
      Turbolinks.visit(url);
    }
  }
}
