import _ from 'lodash';
import { nodeHeight, WIDTH as NODE_WIDTH } from '../../components/Flow/Nodes/Node';
import { MAX_SCALE, MIN_SCALE } from '../../components/Flow/util/hooks/viewport';
import { getNodeConfig } from './node';

function sceneBBox(scene, config) {
  const x = _(scene.nodes).map('positionX').min() || 0;
  const y = _(scene.nodes).map('positionY').min() || 0;
  const maxNodeX = NODE_WIDTH + 60 + (_(scene.nodes).map('positionX').max() || 0);
  const maxNodeY = _(scene.nodes)
    .map((node) => nodeHeight(getNodeConfig(config, node), node) + node.positionY)
    .max() || 0;
  return {
    x,
    y,
    width: maxNodeX - x,
    height: maxNodeY - y,
  };
}

export function fitToScene(scene, config, containerRef) {
  if (!containerRef.current || !scene) { return; }

  const width = containerRef.current.offsetWidth;
  const height = containerRef.current.offsetHeight;
  const requiredBBox = sceneBBox(scene, config);
  const requiredScale = Math.min(width / requiredBBox.width, height / requiredBBox.height);
  const scale = Math.max(Math.min(requiredScale, MAX_SCALE), MIN_SCALE);
  const center = {
    x: ((requiredBBox.x + requiredBBox.width) / 2) * scale,
    y: ((requiredBBox.y + requiredBBox.height) / 2) * scale,
  };
  return {
    origin: { x: width / 2 - center.x, y: height / 2 - center.y },
    scale,
  };
}

export default fitToScene;
