const COLORS = {
  template: '#00A796',
  typed_field: '#6E40C1',
  content: '#354397',
  integer: '#319DF4',
  float: '#7CD6F2',
  string: '#FFFA79',
  array: '#FFFFFF',
  boolean: '#EB334B',
  date: '#B44D31',
  datetime: '#FE6431',
  time: '#FBA071',
  enum: '#af40c1',
  media: '#FFCC0E',
  comparator_hash: '#a29bfe',
  hash: '#FDA7DF',
  data: '#FFFFFF',
  none: '#FFFFFF',
};

export function colorFromDataType(dataType, secondaryType = null) {
  if (secondaryType) { return COLORS[secondaryType]; }
  return COLORS[dataType];
}

export default colorFromDataType;
