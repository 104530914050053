import { Controller } from 'stimulus';
import { updateInputValue } from '../../util/ui';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.saveValues();
  }

  cancel() {
    this.values.forEach(this.updateValue);
  }

  save() {
    this.saveValues();
  }

  saveValues() {
    this.values = this.inputTargets.map((el) => [el.id, $(el).val()]);
  }

  updateValue = ([id, value]) => {
    const input = this.inputTargets.find((el) => el.id === id);
    if (!input) { return; }

    updateInputValue(input, value);
  };
}
