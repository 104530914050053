import { Controller } from 'stimulus';
import _ from 'lodash';
import { fetchWithAuth, filterOKResponse } from '../../util/network';

export default class extends Controller {
  static targets = ['destroyInput'];

  replaceForm() {
    fetchWithAuth(this.url, {
      method: 'PUT',
      body: this.formData,
    })
      .then(filterOKResponse)
      .then((response) => response.text())
      .then((markup) => $(this.element).replaceWith($(markup).find('.object-item-parent')));
  }

  destroy() {
    this.destroyInputTarget.value = '1';
    this.element.classList.add('hide');
  }

  get url() {
    return this.data.get('url');
  }

  get formData() {
    const data = new FormData();
    _.each($(this.element).find('input[name],select[name]').serializeArray(), ({ name, value }) => data.append(name, value));
    return data;
  }
}
