import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';
import notify from '../../app/notify';

export default class extends Controller {
  static targets = ['progressBar', 'name', 'form', 'fileInput', 'nameInput'];

  // Events
  upload(file) {
    const url = this.fileInputTarget.dataset.directUploadUrl;
    const upload = new DirectUpload(file, url, this);

    upload.create((error, blob) => {
      if (error) {
        console.log('failed to upload file', error); // eslint-disable-line no-console
        notify('Upload Error', 'Failed to upload the file', 'warning');
        $(this.element).remove();
      } else {
        this.fileInputTarget.type = 'hidden';
        this.fileInputTarget.value = blob.signed_id;
        $(this.formTarget).submit();
      }
    });
  }

  // Direct Upload Events
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress',
      (event) => this.directUploadDidProgress(event));
  }

  directUploadDidProgress({ loaded, total }) {
    const progress = (loaded / total) * 100;
    $(this.progressBarTarget).css({ width: `${progress}%` }).attr('aria-valuenow', progress);
  }
}
