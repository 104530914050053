import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['form', 'kind'];

  select(e) {
    if (e.currentTarget.classList.contains('active')) { return; }
    this.disableAll();
    e.currentTarget.classList.add('active');
    this.formUrl = e.currentTarget.getAttribute('data-url');
  }

  disableAll() {
    $(this.kindTargets).removeClass('active');
  }

  set formUrl(url) {
    this.formTarget.action = url;
  }
}
