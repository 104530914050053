import React from 'react';
import PropTypes from 'prop-types';
import { childrenType } from '../../../util/types';

export default function PermissionItem({ title, children }) {
  return (
    <li className="permission-item">
      <div className="permissions-row-grid">
        <div className="mi">
          <span>{title}</span>
        </div>
        {children}
      </div>
    </li>
  );
}

PermissionItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, childrenType]).isRequired,
  children: childrenType.isRequired,
};
