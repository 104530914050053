import { throttle } from 'lodash';
import { Controller } from 'stimulus';
import { triggerEvent } from '../app/event';
import ActiveStorageUploadAdapter from '../util/ckeditor/active_storage_upload_adapter';
import CKEditorIframePlugin from '../util/ckeditor/iframe_plugin';

function CustomUploadAdapterPlugin(editor) {
  // eslint-disable-next-line no-param-reassign
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => (new ActiveStorageUploadAdapter(loader, editor));
}

export default class extends Controller {
  connect() {
    const onChange = throttle((editor) => {
      triggerEvent(this.element.form, 'change', { force: true });
      this.updateLivePreview(editor);
    }, 200, { leading: false, trailing: true });

    window.ckeditorcontroller = this;
    ClassicEditor
      .create(this.element,
        {
          extraPlugins: [CustomUploadAdapterPlugin, CKEditorIframePlugin],
          customUpload: {
            directUploadUrl: this.data.get('direct-upload-url'),
            blobUrlTemplate: this.data.get('blob-url-template'),
          },
          htmlSupport: {
            allow: [
              {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true,
              },
            ],
          },
          mediaEmbed: {
            // Enable generation of full iframe code when embedding media
            previewsInData: true,
            // Remove providers not supported by CKEditor generated media previews
            removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook'],
          },
        })
      .then((editor) => {
        if (this.data.get('readonly') === 'true') {
          editor.enableReadOnlyMode('editor');
        }
        editor.model.document.on('change:data', () => onChange(editor));
        this.updateLivePreview(editor);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Failed to create editor', error);
      });
  }

  updateLivePreview(editor) {
    const iframe = document.querySelector(this.data.get('preview-iframe'));
    if (!iframe) { return; }

    iframe.contentWindow.postMessage({ body: editor.getData() }, '*');

    iframe.onload = () => {
      iframe.contentWindow.postMessage({ body: editor.getData() }, '*');
    };
  }
}
