import _ from 'lodash';
import humps from 'lodash-humps';
import { TYPE_UPDATE_PERMISSIONS } from './reducer';
import { fetchWithAuth } from '../../../util/network';

const permissionUrls = () => $('#permission-urls').data();
const permissionsPath = () => permissionUrls().permissionsPath;
const destroyPermissionPath = ({ id }) => _.replace(permissionUrls().deletePermissionPath, ':id', id);
const createPermissionPath = () => permissionUrls().newPermissionPath;

export async function reloadPermissions(dispatch) {
  const response = await fetchWithAuth(permissionsPath());
  const { permissions } = await response.json();
  return dispatch({ type: TYPE_UPDATE_PERMISSIONS, permissions: humps(permissions) });
}

export async function createPermission(dispatch, permission, reloadAll = true) {
  await fetchWithAuth(createPermissionPath(), {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ permission }),
  });
  return reloadAll && reloadPermissions(dispatch);
}

export async function destroyPermission(dispatch, permission, reloadAll = true) {
  await fetchWithAuth(destroyPermissionPath(permission), { method: 'delete' });
  return reloadAll && reloadPermissions(dispatch);
}

export async function destroyPermissions(dispatch, permissions) {
  await Promise
    .all(permissions.map((p) => destroyPermission(dispatch, p, false)));
  return reloadPermissions(dispatch);
}

export async function createPermissions(dispatch, permissions) {
  await Promise
    .all(permissions.map((p) => createPermission(dispatch, p, false)));
  return reloadPermissions(dispatch);
}
