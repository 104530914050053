import React from 'react';
import PropTypes from 'prop-types';
import { Rect, Text } from 'react-konva';
import { positionPropTypes } from '../../../../util/flow/types';
import Theme from '../../util/theme';

const textProps = {
  width: 40,
  height: 26,
  lineHeight: 26,
  offsetY: -2,
  fill: 'white',
  fontFamily: 'Roboto',
  fontStyle: 'bold',
  fontSize: 12,
  align: 'center',
  verticalAlign: 'middle',
};

export default function SegmentedControl({
  x, y, valueA, valueB, value, onChange,
}) {
  return (
    <>
      <Rect
        x={x}
        y={y}
        width={83}
        height={28}
        fill={Theme.COLOR_TERTIARY_BG}
        cornerRadius={4}
      />
      <Rect
        x={x + 1}
        y={y + 1}
        width={40}
        height={26}
        fill={value === valueA ? Theme.COLOR_ACTIVE : Theme.COLOR_SCEONDARY_BG}
        cornerRadius={[4, 0, 0, 4]}
      />
      <Text
        x={x + 1}
        y={y + 1}
        text={valueA}
        opacity={value === valueA ? 1 : 0.5}
        onClick={() => onChange(valueA)}
        {...textProps}
      />
      <Rect
        x={x + 42}
        y={y + 1}
        width={40}
        height={26}
        fill={value === valueB ? Theme.COLOR_ACTIVE : Theme.COLOR_SCEONDARY_BG}
        cornerRadius={[0, 4, 4, 0]}
      />
      <Text
        x={x + 42}
        y={y + 1}
        text={valueB}
        opacity={value === valueB ? 1 : 0.5}
        onClick={() => onChange(valueB)}
        {...textProps}
      />
    </>
  );
}

SegmentedControl.propTypes = {
  ...positionPropTypes,
  valueA: PropTypes.string.isRequired,
  valueB: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
