import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Group } from 'react-konva';
import _ from 'lodash';
import produce from 'immer';
import Header from './Header';
import Content, { contentHeight } from './Content';
import { nodePropType, connectionPropType } from '../../../util/flow/types';
import { DispatchContext } from '../util/contexts';
import ContextMenu, { useContextMenuState } from './ContextMenu';
import { useNodeConfig } from '../util/hooks/core';

const HEADER_HEIGHT = 30;
const TOP_PADDING = 10;
const BOTTOM_PADDING = 30;

export const WIDTH = 280;

export function nodeHeight(nodeConfig, node = null) {
  const minHeight = HEADER_HEIGHT + TOP_PADDING + BOTTOM_PADDING;
  if (!nodeConfig) { return minHeight; }

  return minHeight + contentHeight(nodeConfig, node);
}

export default function Node({ node, connections }) {
  const nodeConfig = useNodeConfig(node);
  const dispatch = useContext(DispatchContext);
  const [contextMenuState, onClick] = useContextMenuState(node);
  const moveNode = produce((draft, event) => {
    draft.positionX = event.target.x();
    draft.positionY = event.target.y();
  });
  const height = nodeHeight(nodeConfig, node);

  return (
    <>
      <Group
        id={`node_${node.id}`}
        x={node.positionX}
        y={node.positionY}
        width={WIDTH}
        height={height}
        draggable={node.editable}
        onDragMove={(e) => dispatch({ type: 'update-node-intermediate', node: moveNode(node, e) })}
        onDragEnd={(e) => dispatch({ type: 'update-node', node: moveNode(node, e) })}
        onClick={onClick}
      >
        <Content
          width={WIDTH}
          height={height}
          node={node}
          connections={connections}
          config={nodeConfig}
        />
        <Header width={WIDTH} height={HEADER_HEIGHT} node={node} />
        <ContextMenu
          visible={contextMenuState.visible}
          node={node}
          x={contextMenuState.x}
          y={contextMenuState.y}
        />
      </Group>
    </>
  );
}

Node.propTypes = {
  node: nodePropType.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};
