import React from 'react';
import PropTypes from 'prop-types';
import { Rect } from 'react-konva';
import Theme from '../../util/theme';

function fill(type, state) {
  if (type !== 'logic') { return Theme.COLOR_SCEONDARY_BG; }
  return ['connected', 'drawing'].includes(state) ? Theme.COLOR_ACTIVE : Theme.COLOR_SCEONDARY_BG;
}

export default function PlugBackground({ type, state, direction }) {
  const cornerRadius = direction === 'in' ? [5, 0, 0, 5] : [0, 5, 5, 0];

  return (
    <Rect
      x={0}
      y={0}
      width={30}
      height={30}
      fill={fill(type, state)}
      cornerRadius={cornerRadius}
    />
  );
}

PlugBackground.propTypes = {
  type: PropTypes.string,
  state: PropTypes.string,
  direction: PropTypes.oneOf(['in', 'out']).isRequired,
};

PlugBackground.defaultProps = {
  state: null,
  type: null,
};
