import PropTypes from 'prop-types';
import _ from 'lodash';

export const nodePropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  positionX: PropTypes.number.isRequired,
  positionY: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  input: PropTypes.any, // eslint-disable-line react/forbid-prop-types
});

export const connectionPropType = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string.isRequired,
  leftId: PropTypes.number,
  rightId: PropTypes.number,
  leftKey: PropTypes.string,
  rightKey: PropTypes.string,
});

export const inputPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  selector: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
  }),
});

export const outputPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const positionPropTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export const selectorPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.any),
});

export const nodeConfigPropType = PropTypes.shape({
  nodeName: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(inputPropType),
  outputs: PropTypes.arrayOf(outputPropType),
  signals: PropTypes.arrayOf(PropTypes.string),
  selectors: PropTypes.arrayOf(selectorPropType),
});

export const nodeSpecializationsConfigPropType = {
  isRequired: (props, propName, componentName, ...rest) => {
    _(props[propName])
      .flatMap((key, value) => nodeConfigPropType({ [key]: value }, key, componentName, ...rest))
      .compact()
      .value();
  },
};

export const configPropType = PropTypes.shape({
  nodes: PropTypes.arrayOf(nodeConfigPropType).isRequired,
});

export const scenePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  nodes: PropTypes.arrayOf(nodePropType).isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
});

export const drawingPropType = PropTypes.shape({
  connection: connectionPropType,
});

export const typedFieldValuePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  flowType: PropTypes.string,
  secondaryFlowType: PropTypes.string,
});

export const comparatorValueType = PropTypes.shape({
  typedField: typedFieldValuePropTypes,
  operator: PropTypes.string,
  value: PropTypes.any,
});

export const cornerRadiusPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.number),
  PropTypes.number,
]);
