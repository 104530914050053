import _ from 'lodash';
import { ioNameFromKey } from './plug/core';

export function connectionType(className) {
  const match = /Flow::(\w+)Connection/.exec(className);
  if (!match) { return null; }
  return _.lowerCase(match[1]);
}

function comparatorHashBuilderConnectionDataType(connection, nodeConfig, node) {
  const [, index, type] = connection?.rightKey?.split('.');
  switch (type) {
    case 'typed_field':
      return ['typed_field'];
    case 'operator':
      return ['string'];
    case 'value':
    {
      const tfs = _.find(nodeConfig.selectors, { name: 'typed_field' }).values;
      const predicate = node?.input?.comparatorHashBuilder?.[index]?.typedField;
      if (!predicate) { return ['data']; }

      const tf = _.find(tfs, predicate);
      return _.compact([tf?.flowType ?? 'data', tf?.secondaryFlowType]);
    }
    default: return ['data'];
  }
}

export function connectionDataType(
  connection, leftNodeConfig, rightNodeConfig, leftNode, rightNode,
) {
  if (connectionType(connection?.type) === 'logic') { return 'logic'; }
  const output = _.find(leftNodeConfig?.outputs, { name: ioNameFromKey(connection?.leftKey) });
  const input = _.find(rightNodeConfig?.inputs, { name: ioNameFromKey(connection?.rightKey) });

  if (input?.type === 'array') { return [input?.type, input?.arrayType]; }
  if (output?.type === 'array') { return [output?.type, output?.arrayType]; }
  if (input?.type === 'comparator_hash_builder') {
    return comparatorHashBuilderConnectionDataType(connection, rightNodeConfig, rightNode);
  }
  return [input?.type ?? output?.type ?? 'data'];
}

export default connectionType;
