import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useImmerReducer } from 'use-immer';
import _ from 'lodash';
import { templateType, permissionType } from '../../util/permissions';
import reducer from './util/reducer';
import * as actions from './util/actions';
import { childrenType } from '../../util/types';

const coreContext = createContext();

function useCoreProvider({ permissions: initialPermissions, templates }) {
  const [{ permissions }, dispatch] = useImmerReducer(reducer, { permissions: initialPermissions });
  return { permissions, templates, dispatch };
}

export function CoreProvider({ permissions, templates, children }) {
  const { dispatch, ...core } = useCoreProvider({ permissions, templates });
  const boundActions = _.mapValues(actions, (action) => action.bind(window, dispatch));
  return (
    <coreContext.Provider value={{ ...core, dispatch, actions: boundActions }}>
      {children}
    </coreContext.Provider>
  );
}

export function useCoreContext() {
  return useContext(coreContext);
}

CoreProvider.propTypes = {
  permissions: PropTypes.arrayOf(permissionType).isRequired,
  templates: PropTypes.arrayOf(templateType).isRequired,
  children: childrenType.isRequired,
};
