import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['kind', 'hideForApiUsers', 'showForApiUsers', 'tokenRow', 'tokenField'];

  connect() {
    this.updateKind(this.kindTarget.value);
  }

  kindChanged(e) {
    this.updateKind(e.target.value);
  }

  updateKind(kind) {
    if (kind === 'api_user') {
      this.tokenRow.removeClass('col-md-7').addClass('col-md-12');
      $(this.hideForApiUsersTargets).hide().find('input').val('');
      return $(this.showForApiUsersTargets).show();
    }
    this.tokenRow.removeClass('col-md-12').addClass('col-md-7');
    $(this.showForApiUsersTargets).hide();
    return $(this.hideForApiUsersTargets).show();
  }

  refreshToken(e) { // eslint-disable-line class-methods-use-this
    e.preventDefault();
    $.getScript($(e.currentTarget).data('refreshTokenUrl'));
  }

  copyToken(e) {
    e.preventDefault();
    const $elem = $('<textarea>').val(this.tokenFieldTarget.value)
      .appendTo(document.body).focus()
      .select();
    document.execCommand('copy');
    $elem.remove();
  }

  get tokenRow() {
    return $(this.tokenRowTarget);
  }
}
