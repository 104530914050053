import sceneReducer from './scene';
import nodeReducer from './node';
import plugReducer from './plug';
import drawingReducer from './drawing';
import uiReducer from './ui';

export default function reducer(draft, action) {
  [
    sceneReducer,
    nodeReducer,
    plugReducer,
    drawingReducer,
    uiReducer,
  ].forEach((reduce) => reduce(draft, action));
}
