import React from 'react';
import PropTypes from 'prop-types';
import { Path } from 'react-konva';

const imagePathPropTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

function ImagePath({
  data, width, height, ...props
}) {
  return (
    <Path
      x={0}
      y={0}
      scaleX={width / 30}
      scaleY={height / 30}
      data={data}
      fill="white"
      {...props}
    />
  );
}
ImagePath.propTypes = {
  ...imagePathPropTypes,
  data: PropTypes.string.isRequired,
};

export function Check(props) {
  return (
    <ImagePath
      data="M12.76,20,8.21,15.47a.7.7,0,0,1,0-1h0l1-1a.68.68,0,0,1,1,0h0l3.06,3.06L19.82,10a.69.69,0,0,1,1,0h0l1,1a.7.7,0,0,1,0,1h0l-8,8.05a.69.69,0,0,1-1,0h0Z"
      {...props}
    />
  );
}
Check.propTypes = imagePathPropTypes;

export function Cube(props) {
  return (
    <ImagePath
      data="M14.54,8.2,8.85,10.34A1.31,1.31,0,0,0,8,11.57v6.15a1.33,1.33,0,0,0,.72,1.18l5.69,2.84a1.29,1.29,0,0,0,1.18,0l5.69-2.84A1.33,1.33,0,0,0,22,17.72V11.57a1.31,1.31,0,0,0-.85-1.23L15.46,8.21a1.22,1.22,0,0,0-.92,0ZM15,9l6.13,2.3h0L15,13.93,8.87,11.27h0ZM8.87,12.23l5.69,2.46v6.14L8.87,18Zm6.57,8.6V14.69l5.69-2.46V18Z"
      {...props}
    />
  );
}
Cube.propTypes = imagePathPropTypes;

export function Cubes(props) {
  return (
    <ImagePath
      data="M19,13.72V10.2a1.11,1.11,0,0,0-.73-1L15.39,8.07a1.19,1.19,0,0,0-.79,0L11.73,9.15a1.12,1.12,0,0,0-.73,1v3.52L7.73,15A1.11,1.11,0,0,0,7,16v3.33a1.13,1.13,0,0,0,.62,1l3.08,1.54a1.12,1.12,0,0,0,1,0L15,20.24l3.29,1.64a1.12,1.12,0,0,0,1,0l3.08-1.54a1.13,1.13,0,0,0,.62-1V16A1.11,1.11,0,0,0,22.27,15Zm-3.62,1.09V11.75l2.87-1v3.05ZM11.75,9.94,15,8.72l3.25,1.22h0L15,11.09,11.75,10Zm0,.82,2.88,1v3.06l-2.88-1Zm-.94,10.29-3-1.51V16.62l3,1.23Zm-3-5.27h0l3.42-1.28,3.38,1.26v0l-3.38,1.37Zm3.81,2.07,3-1.21v2.92l-3,1.5Zm6.8,3.2-3-1.49V16.64l3,1.22Zm3.81-1.51-3,1.51v-3.2l3-1.23Zm0-3.76-3.42,1.38-3.37-1.37v0l3.37-1.26,3.42,1.28Z"
      {...props}
    />
  );
}
Cubes.propTypes = imagePathPropTypes;

export function Minus(props) {
  return (
    <ImagePath
      data="M21.42,13.83H8.58a.58.58,0,0,0-.58.59v1.16a.58.58,0,0,0,.58.59H21.42a.58.58,0,0,0,.58-.59h0V14.42A.58.58,0,0,0,21.42,13.83Z"
      {...props}
    />
  );
}
Minus.propTypes = imagePathPropTypes;

export function Pencil(props) {
  return (
    <ImagePath
      data="M20.67,12.33l-1.08,1.08a.28.28,0,0,1-.4,0h0l-2.6-2.6a.28.28,0,0,1,0-.4h0l1.08-1.08a1.12,1.12,0,0,1,1.59,0l1.41,1.41a1.11,1.11,0,0,1,0,1.58Zm-5-1L9.51,17.49,9,20.34a.55.55,0,0,0,.45.65.67.67,0,0,0,.2,0l2.85-.5,6.16-6.15a.28.28,0,0,0,0-.4h0l-2.6-2.6a.3.3,0,0,0-.41,0Zm-4.6,7.6h1.13v.85l-1.51.26L10,19.33l.26-1.52h.85Z"
      {...props}
    />
  );
}
Pencil.propTypes = imagePathPropTypes;

export function Planes(props) {
  return (
    <ImagePath
      data="M22,15a.67.67,0,0,0-.4-.62l-2.73-1.19L21.6,12a.68.68,0,0,0,.34-.9.65.65,0,0,0-.34-.35L15.44,8.09a1.12,1.12,0,0,0-.88,0L8.4,10.76A.69.69,0,0,0,8.4,12l2.73,1.19L8.4,14.38a.68.68,0,0,0-.34.9.63.63,0,0,0,.34.34l2.73,1.19L8.4,18a.68.68,0,0,0-.34.9.65.65,0,0,0,.34.35l6.16,2.67h0a1.1,1.1,0,0,0,.87,0l6.16-2.68a.67.67,0,0,0,0-1.24l-2.72-1.18,2.73-1.18A.68.68,0,0,0,22,15ZM9.24,11.39l5.68-2.47h0a.19.19,0,0,1,.15,0l5.69,2.47-5.69,2.47a.19.19,0,0,1-.15,0Zm11.52,7.22-5.69,2.47a.19.19,0,0,1-.15,0L9.24,18.61l3-1.31,2.3,1h0a1.1,1.1,0,0,0,.87,0l2.3-1,3,1.31Zm-5.69-1.14a.19.19,0,0,1-.15,0L9.24,15l3-1.31,2.3,1h0a1.1,1.1,0,0,0,.87,0l2.3-1,3,1.31Z"
      {...props}
    />
  );
}
Planes.propTypes = imagePathPropTypes;

export function Plus(props) {
  return (
    <ImagePath
      data="M21.42,13.83H16.17V8.58A.58.58,0,0,0,15.58,8H14.42a.59.59,0,0,0-.59.58h0v5.25H8.58a.58.58,0,0,0-.58.59H8v1.16a.58.58,0,0,0,.58.59h5.25v5.25a.58.58,0,0,0,.59.58h1.16a.59.59,0,0,0,.59-.58h0V16.17h5.25a.58.58,0,0,0,.58-.59h0V14.42a.58.58,0,0,0-.58-.59Z"
      {...props}
    />
  );
}
Plus.propTypes = imagePathPropTypes;

export function Chevron(props) {
  return (
    <ImagePath
      data="M14.61,17.87l-4.45-4.45a.55.55,0,0,1,0-.77l.52-.52a.54.54,0,0,1,.78,0L15,15.65l3.54-3.52a.54.54,0,0,1,.78,0l.52.52a.55.55,0,0,1,0,.77l-4.45,4.45a.54.54,0,0,1-.78,0Z"
      {...props}
    />
  );
}
Chevron.propTypes = imagePathPropTypes;
