import React from 'react';
import PropTypes from 'prop-types';
import { Group, Rect } from 'react-konva';
import _ from 'lodash';
import Theme from '../util/theme';
import Signals, { signalsHeight } from './Signals';
import Outputs, { outputsHeight } from './Outputs';
import Inputs from './Inputs';
import ActionInput, { actionInputsHeight } from './Inputs/ActionInput';
import { nodePropType, nodeConfigPropType, connectionPropType } from '../../../util/flow/types';
import { inputsHeight } from '../../../util/flow/ui/height';
import { fieldComponent } from './Inputs/Field';
import { useConfig } from '../util/hooks/core';

function filterConnections(connections, node, kind) {
  switch (kind) {
    case 'data_in':
      return _.filter(connections, { type: 'Flow::DataConnection', rightId: node.id });
    case 'data_out':
      return _.filter(connections, { type: 'Flow::DataConnection', leftId: node.id });
    case 'flow_in':
      return _.filter(connections, { type: 'Flow::LogicConnection', rightId: node.id });
    case 'flow_out':
      return _.filter(connections, { type: 'Flow::LogicConnection', leftId: node.id });
    default: return [];
  }
}

export function contentHeight(nodeConfig, node) {
  return _.max([
    inputsHeight(nodeConfig, node, fieldComponent) + actionInputsHeight(nodeConfig, node),
    signalsHeight(nodeConfig, node) + outputsHeight(nodeConfig, node),
  ]);
}

export default function Content({
  width, height, node, config, connections,
}) {
  const globalConfig = useConfig();
  return (
    <Group x={0} y={30}>
      <Rect
        x={0}
        y={0}
        width={width}
        height={height - 30}
        fill={Theme.COLOR_PRIMARY_BG}
        cornerRadius={[0, 0, 5, 5]}
        shadowColor="black"
        shadowOpacity={0.35}
        shadowBlur={6}
        shadowEnabled
      />
      <Group listening={!globalConfig.previewMode && node.editable}>
        <ActionInput node={node} config={config} connections={filterConnections(connections, node, 'flow_in')} />
        <Inputs node={node} config={config} connections={filterConnections(connections, node, 'data_in')} />
        <Outputs node={node} config={config} connections={filterConnections(connections, node, 'data_out')} />
        <Signals node={node} config={config} connections={filterConnections(connections, node, 'flow_out')} />
      </Group>
    </Group>
  );
}

Content.propTypes = {
  node: nodePropType.isRequired,
  config: nodeConfigPropType.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};
