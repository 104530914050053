import {
  useContext, useState, useMemo, useEffect,
} from 'react';
import { StageContext } from '../contexts';

export function useStage() {
  return useContext(StageContext)?.getStage();
}

export function useKonvaNode(nodeId) {
  const stage = useStage();
  if (!nodeId) { return null; }
  return stage?.findOne(`#node_${nodeId}`);
}

export function useScreenshot(stageRef, dependencies) {
  const [imageRefreshTime, setImageRefreshTime] = useState(new Date().getTime());

  const image = useMemo(() => {
    const elem = new Image();
    if (!stageRef.current) { return elem; }

    elem.dataset.refreshTime = imageRefreshTime;
    elem.src = stageRef.current.getStage().toDataURL();
    return elem;
  }, [stageRef, imageRefreshTime]);

  useEffect(() => {
    const timeout = setTimeout(() => setImageRefreshTime(new Date().getTime()), 200);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setImageRefreshTime, stageRef, ...dependencies]);

  return image;
}
