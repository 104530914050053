App.appendNewRows = (tableId, rows, pagination) => {
  const $newRows = $.parseHTML(rows);
  const $table = $(tableId);
  $table.append($newRows);

  if (pagination) {
    $('.pagination').replaceWith(pagination);
    $('.pagination').hide();
  } else {
    $table.find('div.loading-spinner').remove();
  }
  App.triggerTurbolinksLoad();
};
