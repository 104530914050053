import React from 'react';
import _ from 'lodash';
import produce from 'immer';
import { editableFieldPropTypes } from './types';
import EditText from './EditText';
import { getNodeInputValue } from '../../../../util/flow/node';
import { CONNECTION_MARGIN } from '../../util/theme';
import { useDispatch } from '../../util/hooks/core';

export default function TextField({
  x, y, input, node, editable,
}) {
  const userValue = getNodeInputValue(node, input);
  const dispatch = useDispatch();

  const updateNode = (text, intermediate = false) => dispatch({
    type: `update-node${intermediate ? '-intermediate' : ''}`,
    node: produce(node, (draft) => { draft.input[input.name] = text; }),
  });
  return (
    <>
      <EditText
        x={x}
        y={y}
        text={userValue}
        placeholder={input.name}
        showIndicator
        editable={editable}
        onChange={(text) => updateNode(text)}
        onChangeIntermediate={(text) => updateNode(text, true)}
        cancelIntermediateChanges={() => dispatch({ type: 'cancel-intermediate' })}
      />
    </>
  );
}
TextField.propTypes = editableFieldPropTypes;

TextField.height = (input, node) => (
  CONNECTION_MARGIN + EditText.height(getNodeInputValue(node, input) || input.name)
);
