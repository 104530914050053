import React from 'react';
import { templateType } from '../../../../util/permissions';
import Control from '../Control';
import PermissionItem from '../PermissionItem';

export default function Template({ template }) {
  return (
    <PermissionItem title={template.name}>
      <Control name={template.globalId} kind="entity_read" />
    </PermissionItem>
  );
}

Template.propTypes = {
  template: templateType.isRequired,
};
