import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  nodePropType, connectionPropType, typedFieldValuePropTypes, comparatorValueType
} from '../../../../../../util/flow/types';
import Plug from '../../../Plug';
import ImagePlug from '../../../Plug/ImagePlug';
import { CONNECTION_HEIGHT, CONNECTION_MARGIN } from '../../../../util/theme';
import JoinOperatorSelect from './JoinOperatorSelect';

export const plugsPropTypes = {
  node: nodePropType.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
  keyPrefix: PropTypes.string.isRequired,
};

const defaultPlugOptions = {
  x: -40,
  direction: 'in',
  type: 'data',
  borders: 'left',
};

function key(prefix, suffix) {
  return `${prefix}.${suffix}`;
}

const componentHeight = CONNECTION_HEIGHT + CONNECTION_MARGIN;
export default function Plugs({
  keyPrefix, connections, node, typedField, buttonImageComponent,
  value, onButtonAction, inputFieldComponentCallback,
}) {
  return (
    <>
      <Plug
        y={0}
        dataType="typed_field"
        name={key(keyPrefix, 'typed_field')}
        connections={_.filter(connections, { rightKey: key(keyPrefix, 'typed_field') })}
        node={node}
        inputFieldComponentCallback={inputFieldComponentCallback}
        {...defaultPlugOptions}
      />
      <Plug
        y={componentHeight}
        dataType="string"
        name={key(keyPrefix, 'operator')}
        connections={_.filter(connections, { rightKey: key(keyPrefix, 'operator') })}
        node={node}
        inputFieldComponentCallback={inputFieldComponentCallback}
        {...defaultPlugOptions}
      />
      <Plug
        y={2 * componentHeight}
        dataType={typedField?.flowType}
        secondaryDataType={typedField?.secondaryFlowType}
        name={key(keyPrefix, 'value')}
        connections={_.filter(connections, { rightKey: key(keyPrefix, 'value') })}
        node={node}
        inputFieldComponentCallback={inputFieldComponentCallback}
        {...defaultPlugOptions}
      />
      <ImagePlug
        x={-40}
        y={JoinOperatorSelect.positionY(value)}
        direction="in"
        onClick={onButtonAction}
        Image={buttonImageComponent}
      />
    </>
  );
}

Plugs.propTypes = {
  ...plugsPropTypes,
  typedField: typedFieldValuePropTypes,
  buttonImageComponent: PropTypes.func.isRequired,
  onButtonAction: PropTypes.func.isRequired,
  inputFieldComponentCallback: PropTypes.func.isRequired,
  value: comparatorValueType,
};

Plugs.defaultProps = {
  typedField: null,
  value: null,
};
