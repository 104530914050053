import { isEqual } from 'lodash';
import { Controller } from 'stimulus';

export default class TemplateFormController extends Controller {
  static targets = ['form', 'ttfIdInput', 'targetTTFIdInput', 'targetPositionInput'];

  connect() {
    this.templateTypedFieldIds = this.ttfIdInputTargets.map((input) => input.value);
  }

  saveTFPositions() {
    const updatedIds = this.ttfIdInputTargets.map((input) => input.value);
    if (isEqual(updatedIds, this.templateTypedFieldIds)) { return; }

    this.targetTTFIdInputTargets.forEach((input) => {
      const { index } = input.dataset;
      const positionInput = this.targetPositionInputTargets.find((i) => i.dataset.index === index);
      positionInput.value = updatedIds.indexOf(input.value);
    });
    this.templateTypedFieldIds = updatedIds;
    Rails.fire(this.formTarget, 'submit');
  }
}
