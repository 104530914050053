import { Controller } from 'stimulus';
import { shouldHandle } from '../util/ui';
import { fetchWithAuth } from '../util/network';

export default class extends Controller {
  connect = () => {
    if (this.data.get('returnTo')) {
      this.modal
        .modal('show')
        .on('hidden.bs.modal', () => {
          document.location = this.data.get('returnTo');
        });
    }
  }

  show = (event) => {
    if (!shouldHandle(event)) { return; }
    if (this.skipDoubleClick()) { return; }
    event.preventDefault();

    const { currentTarget } = event;
    if (!this.isShown) { return this.showModal(currentTarget); }

    this.modal.on('hidden.bs.modal', () => this.showModal(currentTarget)).modal('hide');
  }

  showModal(target) {
    const navigate = target.dataset.modalSkipNavigation !== 'true';
    const returnTo = document.location.href;
    const source = target.dataset.modalSource;
    const sourceParts = source.match(/(.+?)(\?.+)?$/);
    fetchWithAuth(`${sourceParts[1]}.modal${sourceParts[2] || ''}`).then((response) => response.text())
      .then((markup) => {
        this.modal.replaceWith(markup);
        this.modal
          .modal('show')
          .on('hidden.bs.modal', () => {
            window.history.pushState('Navigation', 'Hide modal', returnTo);
          });
        if (!navigate) { return; }
        window.history.pushState('Navigation', 'Show modal', source);
      });
  }

  skipDoubleClick() {
    if (this.showHandledTime && new Date().getTime() - this.showHandledTime < 1000) { return true; }
    this.showHandledTime = new Date().getTime();
    return false;
  }

  hide() {
    this.modal.modal('hide');
  }

  get modal() { // eslint-disable-line class-methods-use-this
    return $('#modal');
  }

  get isShown() {
    const modal = this.modal.data('bs.modal');
    return modal && modal._isShown; // eslint-disable-line no-underscore-dangle
  }
}
