import _ from 'lodash';

export function nodeType(className) {
  const match = /Flow::Nodes::(\w+)(::\w+)+/.exec(className);
  if (!match) { return null; }
  return _.lowerCase(match[1]);
}

export function nodeHasActionInput(className) {
  return nodeType(className) === 'action';
}

export function getNodeConfig(config, node) {
  const specializations = _.find(config?.nodes, { className: node?.className });
  return specializations?.[_.camelCase(node?.typeSpecialization ?? 'default')];
}

export function getNodeInputValue(node, input) {
  return _.get(node, ['input', _.camelCase(input.name)]);
}
