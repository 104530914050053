import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Group } from 'react-konva';
import { positionPropTypes, connectionPropType, nodePropType } from '../../../../util/flow/types';
import { connectionType, connectionDataType } from '../../../../util/flow/connection';
import Icon from './Icon';
import {
  useDispatch, useNodeConfig, useNode, useDrawing,
} from '../../util/hooks/core';
import { useSnapPlugProvider } from '../../util/hooks/connection';
import PlugBackground from './PlugBackground';
import { CONNECTION_MARGIN, CONNECTION_HEIGHT } from '../../util/theme';

function isCorrectDataType(
  dataType, secondaryDataType, expDataType, expSecondaryDataType, plugDirection,
) {
  if (plugDirection === 'out' && dataType === 'array') { return expDataType === dataType && secondaryDataType === expSecondaryDataType; }
  if (plugDirection === 'out' && expDataType === 'array') { return dataType === expSecondaryDataType; }
  if (plugDirection === 'out') { return dataType === expDataType; }

  if (plugDirection === 'in' && dataType === 'array') {
    return (expDataType === dataType && expSecondaryDataType === secondaryDataType)
      || expDataType === secondaryDataType;
  }
  return expDataType === dataType
    && ((_.isNil(secondaryDataType) && _.isNil(expSecondaryDataType))
      || secondaryDataType === expSecondaryDataType);
}

function usePlugState({
  connections, direction, dataType, secondaryDataType, type, node, name,
}) {
  const { connection } = useDrawing();
  const leftNodeConfig = useNodeConfig(useNode(connection?.leftId));
  const rightNodeConfig = useNodeConfig(useNode(connection?.rightId));
  const state = _.isEmpty(connections) ? 'default' : 'connected';
  const expectedType = connectionType(connection?.type);
  const [expectedDataType, expectedSecondaryDataType] = connectionDataType(
    connection, leftNodeConfig, rightNodeConfig,
  );
  const expectedDirection = connection?.leftId ? 'in' : 'out';

  // Is connection beginning from this plug?
  if (
    connection
    && (connection?.leftId === node.id || connection?.rightId === node.id)
    && (connection?.leftKey === name || connection?.rightKey === name)
  ) { return 'drawing'; }

  // Can connection be connected to this plug?
  if (
    connection
    && expectedType === type
    && (expectedType === 'logic' || isCorrectDataType(dataType, secondaryDataType, expectedDataType, expectedSecondaryDataType, expectedDirection))
    && expectedDirection === direction
    && node.id !== (connection?.leftId ?? connection?.rightId)
  ) { return 'active'; }

  return state;
}

export default function Plug({
  x, y, type, direction, dataType, secondaryDataType,
  connections, node, name, inputFieldComponentCallback,
}) {
  const dispatch = useDispatch();
  const state = usePlugState({
    connections, direction, dataType, secondaryDataType, type, node, name,
  });
  const snapPlugProvider = useSnapPlugProvider(inputFieldComponentCallback);
  const dispatchParams = useMemo(() => ({
    node, direction, state, key: name, kind: type,
  }), [node, direction, state, name, type]);

  const handlePlugMouseup = useCallback((e) => {
    e.cancelBubble = true; // eslint-disable-line no-param-reassign
    const plug = snapPlugProvider()?.plug ?? dispatchParams;
    dispatch({ type: 'draw-action-plug-end', ...plug });
  }, [snapPlugProvider, dispatch, dispatchParams]);

  useEffect(() => {
    if (!dispatch) { return; }
    const unregisterPlug = dispatch;
    const id = `${node.className}_${node.id}_${name}`;
    dispatch({ type: 'register-plug', id, plug: dispatchParams });
    return () => unregisterPlug({ type: 'unregister-plug', id });
  }, [dispatch, node, name, dispatchParams]);

  return (
    <Group
      x={x}
      y={y}
      width={30}
      height={30}
      onMouseDown={(e) => {
        e.cancelBubble = true; // eslint-disable-line no-param-reassign
        dispatch({ type: 'draw-action-plug-start', ...dispatchParams });
      }}
      onMouseUp={handlePlugMouseup}
      onDblClick={() => dispatch({ type: 'reset-plug-connections', ...dispatchParams })}
    >
      <PlugBackground direction={direction} type={type} state={state} />
      <Icon
        x={15}
        y={15}
        type={type}
        state={state}
        dataType={dataType}
        direction={direction}
        secondaryDataType={secondaryDataType}
        hasCustomValue={!!_.get(node.input, name)}
      />
    </Group>
  );
}

Plug.propTypes = {
  ...positionPropTypes,
  type: PropTypes.oneOf(['logic', 'data']).isRequired,
  connections: PropTypes.arrayOf(connectionPropType),
  direction: PropTypes.oneOf(['in', 'out']).isRequired,
  dataType: PropTypes.string,
  secondaryDataType: PropTypes.string,
  name: PropTypes.string,
  node: nodePropType.isRequired,
  inputFieldComponentCallback: PropTypes.func,
};

Plug.defaultProps = {
  dataType: 'none',
  secondaryDataType: null,
  connections: [],
  name: null,
  inputFieldComponentCallback: () => CONNECTION_MARGIN + CONNECTION_HEIGHT,
};
