import React from 'react';
import PropTypes from 'prop-types';
import { positionPropTypes } from '../../../../../../util/flow/types';
import SegmentedControl from '../../SegmentedControl';
import { CONNECTION_MARGIN, CONNECTION_HEIGHT } from '../../../../util/theme';
import EditText from '../../EditText';

export default function JoinOperatorSelect({
  x, y, value, onChange,
}) {
  return <SegmentedControl x={x} y={y} valueA="AND" valueB="OR" value={value} onChange={onChange} />;
}

JoinOperatorSelect.propTypes = {
  ...positionPropTypes,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

JoinOperatorSelect.defaultProps = {
  value: 'AND',
};

JoinOperatorSelect.positionY = (comparatorValue, comparatorTop = 0) => (
  comparatorTop
  + 2 * (CONNECTION_HEIGHT + CONNECTION_MARGIN)
  + CONNECTION_MARGIN
  + EditText.height(comparatorValue?.value ?? 'Value')
);
