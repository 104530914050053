import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Rect, Group } from 'react-konva';
import Theme from '../../../util/theme';
import PlugLabel from '../../Plug/PlugLabel';
import Dropdown, { optionsPropType } from './Dropdown';
import { positionPropTypes } from '../../../../../util/flow/types';
import { Chevron } from '../../../util/Images';
import { useStage } from '../../../util/hooks/konva';
import { valueTypes } from './DropdownGroup';

export default function Select({
  x, y, label, options, editable, selectedValue, onChange, onInteractionStateChange,
}) {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [mouseInside, setMouseInside] = useState(false);
  const stage = useStage();
  useEffect(() => {
    onInteractionStateChange(dropdownActive);
  }, [onInteractionStateChange, dropdownActive]);

  useEffect(() => {
    if (!stage || !dropdownActive) { return; }
    const onMouseDown = () => !mouseInside && setDropdownActive(false);
    stage.on('mousedown.select', onMouseDown);
    return () => stage.off('mousedown.select', onMouseDown);
  }, [stage, dropdownActive, mouseInside]);

  return (
    <Group
      x={x}
      y={y}
      onClick={() => editable && setDropdownActive(!dropdownActive && options.length)}
      onMouseEnter={() => setMouseInside(true)}
      onMouseLeave={() => setMouseInside(false)}
    >
      <Rect x={0} y={0} cornerRadius={dropdownActive ? [4, 4, 0, 0] : 4} fill="#25272E" width={158} height={28} />
      <Rect
        x={1}
        y={1}
        width={131}
        height={26}
        cornerRadius={[3, 0, 0, 3]}
        fill={Theme.COLOR_SCEONDARY_BG}
      />
      <Group x={133} y={1} width={24} height={26}>
        <Rect
          x={0}
          y={0}
          cornerRadius={[0, 3, 3, 0]}
          fill={Theme.COLOR_PRIMARY_BG}
          width={24}
          height={26}
        />
        <Chevron x={-3} y={-2} width={30} height={30} />
      </Group>
      <PlugLabel
        x={8}
        y={0}
        width={123}
        height={28}
        text={label}
        align="left"
        opacity={selectedValue ? 1 : 0.5}
      />
      <Dropdown
        x={0}
        y={28}
        options={options}
        value={selectedValue}
        visible={!!dropdownActive}
        onClose={() => setDropdownActive(false)}
        onSelect={(value) => onChange(value)}
      />
    </Group>
  );
}
Select.propTypes = {
  ...positionPropTypes,
  label: PropTypes.string.isRequired,
  options: optionsPropType.isRequired,
  editable: PropTypes.bool,
  onInteractionStateChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType(valueTypes),
  onChange: PropTypes.func,
};

Select.defaultProps = {
  selectedValue: null,
  onChange: () => {},
  onInteractionStateChange: () => {},
  editable: true,
};
