import React from 'react';
import _ from 'lodash';
import PlugLabel from '../Plug/PlugLabel';
import { fieldPropTypes } from './types';
import TextField from './TextField';
import SelectField from './SelectField';
import ArrayField from './ArrayField';
import { nodeType } from '../../../../util/flow/node';
import ComparatorHashBuilderField from './ComparatorHashBuilderField';
import { ioNameFromKey } from '../../../../util/flow/plug/core';

export function fieldComponent(input) {
  if (input.selector?.name) { return SelectField; }

  switch (input.type) {
    case 'string': return TextField;
    case 'integer': return TextField;
    case 'float': return TextField;
    case 'enum': return TextField;
    case 'array': return ArrayField;
    case 'comparator_hash_builder': return ComparatorHashBuilderField;
    default: return null;
  }
}

export function fieldHasPlug(input, node) {
  const component = fieldComponent(input);
  if (component && typeof component.hasPlug === 'function') {
    return component.hasPlug(input, node);
  }
  return nodeType(node.className) !== 'event';
}

export default function Field({
  x, y, input, node, connections, onInteractionStateChange,
}) {
  const Component = fieldComponent(input);
  if (!Component) {
    return <PlugLabel x={x} y={y} text={input.name} align="left" />;
  }

  const editable = !_.some(
    connections,
    (connection) => ioNameFromKey(connection.rightKey) === input.name,
  );

  return (
    <Component
      x={x}
      y={y}
      input={input}
      node={node}
      editable={(_.isNil(input.editable) ? true : input.editable) && editable}
      connections={connections}
      onInteractionStateChange={onInteractionStateChange}
      inputFieldComponentCallback={fieldComponent}
    />
  );
}

Field.propTypes = fieldPropTypes;
