import { Controller } from 'stimulus';
import consumer from '../../app/consumer';

export default class extends Controller {
  connect() {
    this.subscription = consumer.subscriptions.create({ channel: 'UserChannel' }, {
      received(message) {
        switch (message.type) {
          case 'script':
            return eval(message.data); // eslint-disable-line no-eval
          default: break;
        }
      },
    });
  }

  disconnect() {
    this.subscription.unsubscribe();
  }
}
