import React from 'react';
import PropTypes from 'prop-types';
import { Group, Rect, Text } from 'react-konva';
import { nodePropType } from '../../../util/flow/types';
import { nodeType } from '../../../util/flow/node';
import Theme from '../util/theme';
import { Planes, Cubes, Cube } from '../util/Images';

function title(node) {
  return node.name;
}

function icon(node) {
  const type = nodeType(node.className);
  const props = { width: 30, height: 30, opacity: 0.35 };
  switch (type) {
    case 'data': return <Planes {...props} />;
    case 'action': return <Cubes {...props} />;
    default: return <Cube {...props} />;
  }
}

export default function Header({ width, height, node }) {
  return (
    <Group>
      <Rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill={Theme.COLOR_SCEONDARY_BG}
        cornerRadius={[5, 5, 0, 0]}
        shadowColor="black"
        shadowOpacity={0.35}
        shadowBlur={6}
        shadowEnabled
      />
      <Text x={0} y={0} width={width} height={height} text={title(node)} fill="white" fontFamily="Roboto" fontSize={14} fontStyle="bold" align="center" verticalAlign="middle" />
      {icon(node)}
    </Group>
  );
}

Header.propTypes = {
  node: nodePropType.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
