import { Controller } from 'stimulus';
import { shouldHandle } from '../util/ui';

export default class extends Controller {
  click(event) {
    if (!shouldHandle(event)) { return; }
    if (this.data.get('remote') === 'true') {
      return $.getScript(this.element.dataset.href);
    }
    Turbolinks.visit(this.element.dataset.href);
  }
}
