import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Node from './Node';
import { nodePropType, connectionPropType } from '../../../util/flow/types';

function connectionsForNode(node, connections) {
  return _.concat(
    _.filter(connections, { leftId: node.id }),
    _.filter(connections, { rightId: node.id }),
  );
}

export default function Nodes({ nodes, connections }) {
  return nodes.map((node) => (
    <Node
      key={node.id || node.className}
      node={node}
      connections={connectionsForNode(node, connections)}
    />
  ));
}

Nodes.propTypes = {
  nodes: PropTypes.arrayOf(nodePropType).isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};
