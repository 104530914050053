import React, { useState } from 'react';
import _ from 'lodash';
import { templateType } from '../../../../util/permissions';
import Control from '../Control';
import { useCoreContext } from '../../Context';
import { destroyPermissions, createPermissions } from '../../util/actions';
import TypedField from './TypedField';

export default function Content({ template }) {
  const { permissions, dispatch } = useCoreContext();
  const [loading, setLoading] = useState({
    read: false,
    create: false,
    update: false,
    destroy: false,
  });

  const hasTemplatePermission = !!_.find(permissions, { name: template.globalId });
  const allowedPermissions = _.filter(
    permissions,
    (p) => _.find(template.typedFields, { globalId: p.name }),
  );
  const allowedKinds = _(allowedPermissions).map('kind').uniq().value();
  const contentAllowed = !!allowedPermissions.length;

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const togglePermission = async (kind) => {
    setLoading({ ...loading, [kind]: true });

    if (_.includes(allowedKinds, `entity_${kind}`)) {
      // Destroy
      await destroyPermissions(dispatch, _.filter(allowedPermissions, { kind: `entity_${kind}` }));
      return setLoading({ ...loading, [kind]: false });
    }

    // Create
    const typedFields = allowedPermissions.length ? _(allowedPermissions).map('name').uniq().value() : _.map(template.typedFields, 'globalId');
    await createPermissions(dispatch, typedFields.map((name) => ({ name, kind: `entity_${kind}` })));
    return setLoading({ ...loading, [kind]: false });
  };

  return (
    <div className="permission-category">
      <div className="permission-category-header">
        <div className="permissions-row-grid">
          <div className="mi">
            <h2>{template.name}</h2>
          </div>
          <Control
            name={`${template.globalId}Content`}
            kind="entity_read"
            loading={loading.read}
            disabled={hasTemplatePermission}
            checked={_.includes(allowedKinds, 'entity_read')}
            onChange={() => togglePermission('read')}
          />
          <Control
            name={`${template.globalId}Content`}
            kind="entity_create"
            loading={loading.create}
            disabled={hasTemplatePermission}
            checked={_.includes(allowedKinds, 'entity_create')}
            onChange={() => togglePermission('create')}
          />
          <Control
            name={`${template.globalId}Content`}
            kind="entity_update"
            loading={loading.update}
            disabled={hasTemplatePermission}
            checked={_.includes(allowedKinds, 'entity_update')}
            onChange={() => togglePermission('update')}
          />
          <Control
            name={`${template.globalId}Content`}
            kind="entity_destroy"
            loading={loading.destroy}
            disabled={hasTemplatePermission}
            checked={_.includes(allowedKinds, 'entity_destroy')}
            onChange={() => togglePermission('destroy')}
          />
          <div className="permission-toggle">
            <a href="#" onClick={toggleExpanded} aria-expanded={expanded} aria-controls="permission-migrations">
              <i className="fa fa-chevron-down" />
            </a>
          </div>
        </div>
      </div>

      <div className={`permission-category-body collapse ${expanded ? 'show' : ''}`} id="permission-migrations">
        <ul>
          {template.typedFields.map((typedField) => (
            <TypedField
              typedField={typedField}
              key={typedField.globalId}
              disabled={hasTemplatePermission || !contentAllowed}
              permissions={_.filter(allowedPermissions, { name: typedField.globalId })}
              kinds={allowedKinds}
              dispatch={dispatch}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

Content.propTypes = {
  template: templateType.isRequired,
};
