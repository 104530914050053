import { Controller } from 'stimulus';
import { triggerEvent } from '../../app/event';

export default class extends Controller {
  static targets = ['form'];

  change() {
    triggerEvent(this.formTarget, 'change');
  }
}
