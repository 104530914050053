import { Controller } from 'stimulus';
import { triggerEvent } from '../../app/event';

export default class extends Controller {
  static targets = ['content'];

  insert() {
    triggerEvent($(this.data.get('insert-selector'))[0], 'add', this.resourcesController.getSelection());
  }

  get resourcesController() {
    return this.application.getControllerForElementAndIdentifier(this.contentTarget, 'media-lib--resources');
  }
}
