import { Controller } from 'stimulus';

export default class extends Controller {
  submit(e) {
    const url = e.target.dataset.formSubmitUrl;
    if (url) { this.url = url; }
    if (this.data.get('remote') === 'true') { return this.submitRemote(e); }
    Rails.fire(this.element, 'submit');
  }

  submitRemote(e) {
    e.preventDefault();
    $.ajax(this.element.action, {
      dataType: 'script',
      method: this.data.get('method') || 'get',
      data: $(this.element).serialize(),
    });
  }

  set url(url) {
    this.element.action = url;
  }
}
