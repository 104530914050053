import { Controller } from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.choices = new Choices(this.inputTarget, {
      delimiter: ',',
      removeItemButton: true,
      editItems: true,
      ...JSON.parse(this.data.get('options') || '{}'),
    });
  }

  disconnect() {
    this.choices.destroy();
  }
}
