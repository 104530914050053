import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Connection from './Connection';
import { connectionPropType, nodePropType } from '../../../util/flow/types';

export default function Connections({ connections, nodes }) {
  return connections.map((connection) => (
    <Connection
      key={`${connection.id}_${connection.leftId}_${connection.leftKey}_${connection.rightId}_${connection.rightKey}`}
      connection={connection}
      left={_.find(nodes, { id: connection.leftId })}
      right={_.find(nodes, { id: connection.rightId })}
      isDrawing={!connection.id}
    />
  ));
}

Connections.propTypes = {
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
  nodes: PropTypes.arrayOf(nodePropType).isRequired,
};
