import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Rect, Group } from 'react-konva';
import _ from 'lodash';
import { positionPropTypes } from '../../../../../util/flow/types';
import Theme from '../../../util/theme';
import DropdownGroup, { valueTypes } from './DropdownGroup';

export default function Dropdown({
  x, y, options, value, visible, onSelect,
}) {
  const [activeIndex, setActiveIndex] = useState();

  if (!visible) { return null; }

  const groups = _(options)
    .map('group').uniq().compact()
    .value();
  const height = (options.length + groups.length) * 28 + 2;
  const grouped = _.groupBy(options, 'group');

  return (
    <Group x={x} y={y} width={158} height={height}>
      <Rect x={0} y={0} cornerRadius={[0, 0, 4, 4]} fill="#25272E" width={158} height={height} />
      <Rect
        x={1}
        y={1}
        width={156}
        height={height - 2}
        cornerRadius={4}
        fill={Theme.COLOR_SCEONDARY_BG}
        shadowColor="black"
        shadowOpacity={0.35}
        shadowBlur={6}
        shadowEnabled
      />
      {(_.isEmpty(groups) ? [undefined] : groups).map((group, groupIndex) => (
        <DropdownGroup
          key={group || groupIndex}
          group={group}
          groups={groups}
          index={groupIndex}
          grouped={grouped}
          value={value}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          onSelect={onSelect}
        />
      ))}
    </Group>
  );
}

export const optionsPropType = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType(valueTypes).isRequired,
}));

Dropdown.propTypes = {
  ...positionPropTypes,
  options: optionsPropType.isRequired,
  value: PropTypes.oneOfType(valueTypes),
  onSelect: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

Dropdown.defaultProps = { visible: true, value: null };
