import { useCallback } from 'react';
import { useDrawing, useFlowRefs, useConfig } from './core';
import { useStage } from './konva';
import { getViewportPosition } from './viewport';
import { findSnapPlug } from '../../../../util/flow/plug/ui';

export function useSnapPlugProvider(fieldComponent) {
  const drawing = useDrawing();
  const stage = useStage();
  const { plugs } = useFlowRefs();
  const config = useConfig();

  return useCallback(() => {
    if (!drawing.connection) { return null; }
    const position = getViewportPosition(stage);
    return findSnapPlug(drawing.connection, plugs, config, position, fieldComponent);
  }, [drawing.connection, stage, config, plugs, fieldComponent]);
}

export default useSnapPlugProvider;
