import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['valueId', 'deleteButton', 'uploadButton'];

  connect() {
    if (this.valueIdTarget.value) { return; }

    const deleteButtonClassAction = this.valueIdTarget.value ? 'remove' : 'add';
    const uploadButtonClassAction = this.valueIdTarget.value ? 'add' : 'remove';
    this.deleteButtonTarget.classList[deleteButtonClassAction]('hide');
    this.uploadButtonTarget.classList[uploadButtonClassAction]('hide');
  }
}
