import _ from 'lodash';
import { Controller } from 'stimulus';
import { filterOKResponse, fetchWithAuth } from '../../util/network';
import { nodeType } from '../../util/flow/node';
import consumer from '../../app/consumer';

export default class extends Controller {
  static targets = ['console', 'consoleWarningIcon', 'form', 'runButton', 'eventNodesSelect'];

  connect() {
    const self = this;
    this.subscription = consumer.subscriptions.create({ channel: 'FlowChannel', id: this.data.get('id') }, {
      received(message) {
        switch (message.type) {
          case 'console_output':
            return self.updateConsole(message.html);
          case 'flow_job_finish':
            if (message.data.uuid === self.simulationId && message.data.scene_id === parseInt(self.data.get('id'), 10)) {
              Rails.enableElement(self.runButtonTarget);
            }
            break;
          default: break;
        }
      },
    });
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  runSimulation() {
    Rails.disableElement(this.runButtonTarget);

    fetchWithAuth(this.formTarget.action, {
      method: 'PUT',
      body: new FormData(this.formTarget),
    })
      .then(filterOKResponse)
      .then((response) => response.json())
      .then(({ uuid }) => { this.simulationId = uuid; });
  }

  refreshEventNodes(e) {
    const { scene } = e.data;
    const select = this.eventNodesSelectTarget;
    const $select = $(select);
    const types = _(scene.nodes)
      .filter((node) => nodeType(node.className) === 'event')
      .map((node) => _.pick(node, 'className', 'name'))
      .uniq()
      .value();

    const existing = _.map(select.options, 'value');
    const newTypes = _.differenceWith(types, existing, (a, b) => a.className === b);
    const removedTypes = _.differenceWith(existing, types, (b, a) => a.className === b);
    _.each(newTypes, (type) => $select.append($('<option>').val(type.className).text(type.name)));
    _.each(_.compact(removedTypes), (className) => $select.find(`option[value="${className}"]`).remove());
  }

  updateConsole(html) {
    this.console.empty().append(html);
    if (!this.console.hasClass('is-active')) {
      $(this.consoleWarningIconTarget).removeClass('hide');
    }
  }

  hideWarningIcon() {
    $(this.consoleWarningIconTarget).addClass('hide');
  }

  get console() {
    return $(this.consoleTarget);
  }

  set jobId(id) {
    this.simulationJobId = id;
  }
}
