import PropTypes from 'prop-types';
import {
  positionPropTypes, nodePropType, inputPropType, connectionPropType,
} from '../../../../util/flow/types';

export const fieldPropTypes = {
  ...positionPropTypes,
  input: inputPropType.isRequired,
  node: nodePropType.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
  onInteractionStateChange: PropTypes.func.isRequired,
};

export const editableFieldPropTypes = {
  ...fieldPropTypes,
  editable: PropTypes.bool.isRequired,
};
