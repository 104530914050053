import _ from 'lodash';
import { PLUG_LABEL_STYLE } from '../../../components/Flow/util/theme';
import { roundUpToMultiple } from '../../ui';

export const MAX_EDIT_TEXT_HEIGHT = 150;

const DEFAULT_STYLE = {
  position: 'absolute',
  top: '-1000px',
  left: '-1000px',
  width: `${PLUG_LABEL_STYLE.width}px`,
  height: 'auto',
  fontSize: `${PLUG_LABEL_STYLE.fontSize}px`,
  lineHeight: `${PLUG_LABEL_STYLE.lineHeight}px`,
  fontFamily: PLUG_LABEL_STYLE.fontFamily,
  border: 'none',
  padding: '0px',
  margin: '0px',
  overflow: 'hidden',
  background: 'none',
  outline: 'none',
  resize: 'none',
  transformOrigin: 'left top',
  paddingTop: '5px',
};

function createElement(name, styles = {}) {
  const elem = document.createElement(name);
  _.each({ ...DEFAULT_STYLE, ...styles }, (value, key) => {
    elem.style[key] = value;
  });
  document.body.appendChild(elem);
  return elem;
}

export function textareaFromTextNode(textNode) {
  const textPosition = textNode.absolutePosition();
  const scale = textNode.getStage().scaleX();
  const stageBox = textNode.getStage().container().getBoundingClientRect();
  const areaPosition = {
    x: stageBox.left + textPosition.x,
    y: stageBox.top + textPosition.y,
  };

  const paddingTop = textNode.verticalAlign() === 'middle' ? 5 : 0;
  const textarea = createElement('textarea', {
    top: `${areaPosition.y}px`,
    left: `${areaPosition.x}px`,
    width: `${textNode.width() - textNode.padding() * 2}px`,
    height: `${textNode.height() - textNode.padding() * 2 + paddingTop}px`,
    fontSize: `${textNode.fontSize()}px`,
    lineHeight: `${textNode.lineHeight() * textNode.fontSize()}px`,
    fontFamily: textNode.fontFamily(),
    textAlign: textNode.align(),
    color: textNode.fill(),
    overflowY: 'scroll',
    wordBreak: 'break-all',
    paddingTop: `${paddingTop}px`,
  });

  textarea.value = textNode.text();
  const rotation = textNode.rotation();
  let transform = `scale(${scale})`;
  if (rotation) {
    transform += `rotateZ(${rotation}deg)`;
  }

  let px = 0;
  // slightly move textarea on firefox because it jumps a bit
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  if (isFirefox) {
    px += 2 + Math.round(textNode.fontSize() / 20);
  }
  transform += `translateY(-${px}px)`;
  textarea.style.transform = transform;
  textarea.style.height = textNode.height();
  textarea.style.maxHeight = `${145 + paddingTop}px`;
  textarea.focus();

  return textarea;
}

const heightCache = {};
export function plugLabelHeight(string) {
  let text = string;
  if (_.endsWith(text, '\n')) { text += 'a'; }
  if (heightCache[text]) { return heightCache[text]; }

  const div = createElement('div', {
    opacity: 0,
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  });
  div.textContent = text;
  document.body.appendChild(div);

  const height = div.scrollHeight;
  div.parentNode.removeChild(div);

  heightCache[text] = height;
  return height;
}

export function substringToFitHeight(text, maxHeight) {
  if (plugLabelHeight(text) <= maxHeight) { return text; }
  let factor = 1.5;
  while (plugLabelHeight(text.substring(0, Math.ceil(text.length / factor))) > maxHeight) {
    factor **= 2;
  }

  let extra = 1;
  while (plugLabelHeight(text.substring(0, Math.ceil(text.length / factor) + extra)) < maxHeight) {
    extra += 1;
  }

  return text.substring(0, Math.ceil(text.length / factor) + extra - 1);
}

export function editTextHeight(text) {
  return Math.min(roundUpToMultiple(plugLabelHeight(text), 30), MAX_EDIT_TEXT_HEIGHT);
}
