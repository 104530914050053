import { Controller } from 'stimulus';
import _ from 'lodash';
import { SIDEBAR_ACTIVE_CLASS } from './sidebar_controller';

export function updateResourceChildren(url, ids) {
  return $.ajax(url, {
    type: 'put',
    data: { resource: { child_ids: ids } },
  });
}

function updateResourcesParent(url, ids) {
  $.ajax(url, { type: 'put', data: { ids, resource: { parent_id: null } } });
}

export default class extends Controller {
  static targets = ['sidebar', 'resource', 'container', 'uploadTemplate', 'fileInput', 'moveButton'];

  connect() {
    this.selectedResources = [];
    this.emptySidebar = this.sidebarTarget.innerHTML;
    this.uploadTemplate = _.template(this.uploadTemplateTarget.outerHTML);
  }

  // Events
  select({ target }) {
    this.selectedResources.push(target.dataset.resourceId);
    this.onSelectionUpdated();
  }

  deselect({ target }) {
    _.remove(this.selectedResources, (id) => id === target.dataset.resourceId);
    this.onSelectionUpdated();
  }

  onSelectionUpdated = _.debounce(() => {
    this.updateSelection();
    this.updateSidebar();
    this.updateMoveButton();
  }, 200);

  openFilePicker() {
    this.fileInputTarget.click();
  }

  upload() {
    const file = _.first(this.fileInputTarget.files);
    this.fileInputTarget.value = '';
    if (!file) { return; }

    const id = new Date().getTime().toString();
    this.containerTarget.insertAdjacentHTML('afterbegin', this.uploadTemplate({ id, name: file.name }));
    const li = this.containerTarget.querySelector('li:first-child');
    setTimeout(() => {
      this.application.getControllerForElementAndIdentifier(li, 'media-lib--upload').upload(file);
    }, 500);
  }

  openMoveSidebar() {
    this.showSidebar(this.data.get('move-url'));
  }

  move(event) {
    event.preventDefault();
    updateResourceChildren(event.currentTarget.href, this.selectedResources);
  }

  moveToRoot(event) {
    event.preventDefault();
    updateResourcesParent(event.currentTarget.href, this.selectedResources);
  }

  // Public
  getSelection() {
    return this.selectedResources.map((id) => ({
      id,
      url: this.resourceElement(id).dataset.resourceUrl,
      preview: this.resourceElement(id).dataset.previewUrl,
      icon: this.resourceElement(id).dataset.icon,
      type: this.resourceElement(id).dataset.type,
    }));
  }

  // Private
  updateSelection() {
    _.forEach(this.resourceTargets, (el) => this.updateElementSelection(el));
  }

  updateElementSelection(el) {
    let operation = 'remove';
    if (_.includes(this.selectedResources, el.dataset.resourceId)) {
      operation = 'add';
    }
    el.classList[operation]('selected');
  }

  updateSidebar() {
    if (this.selectedResources.length !== 1) { return this.closeSidebar(); }

    const el = this.resourceElement(this.selectedResources[0]);
    const url = el.dataset.sidebarUrl;
    this.showSidebar(url);
  }

  updateMoveButton() {
    if (!this.hasMoveButtonTarget) { return; }
    this.moveButtonTarget.disabled = _.isEmpty(this.selectedResources);
  }

  shiftSelectResources(selectedEl) {
    const lastId = _.last(this.selectedResources);
    const startIndex = _.findIndex(this.resourceTargets, (el) => el.dataset.resourceId === lastId);
    const endIndex = _.indexOf(this.resourceTargets, selectedEl);
    const resourceIds = _(this.resourceTargets)
      .slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1)
      .map((el) => el.dataset.resourceId)
      .value();
    this.selectedResources = _.union(this.selectedResources, resourceIds);
  }

  closeSidebar() {
    this.element.classList.remove(SIDEBAR_ACTIVE_CLASS);
  }

  showSidebar(url) {
    $.get(url, (data) => {
      this.element.classList.add(SIDEBAR_ACTIVE_CLASS);
      $(this.sidebarTarget).replaceWith(data);
    });
  }

  resourceElement(id) {
    return _.find(this.resourceTargets, (el) => el.dataset.resourceId === id);
  }
}
