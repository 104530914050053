import { Controller } from 'stimulus';
import Sortable, { MultiDrag } from 'sortablejs';
import _ from 'lodash';
import { triggerEvent } from '../../app/event';

export default class extends Controller {
  static targets = ['container', 'ignoreDeselect'];

  connect() {
    this.applyMultiDragDeselectFix();
    this.selected = [];

    const limit = _.toSafeInteger(this.data.get('limit'));
    this.sortable = new Sortable(this.containerTarget, {
      group: 'media-lib',
      draggable: '.ml-item',
      filter: this.data.get('filter'),
      dataIdAttr: 'data-resource-id',
      multiDrag: true,
      sort: _.isNil(this.data.get('disable-dragging')),
      multiDragKey: 'alt',
      selectedClass: 'selected',
      onSelect: (e) => {
        this.selected.push(e.item);
        triggerEvent(e.item, 'select');
        this.deselect(limit);
      },
      onDeselect: (e) => {
        _.pull(this.selected, e.item);
        triggerEvent(e.item, 'deselect');
      },
      onStart: (e) => {
        this.index = $(e.item).index();
        this.setDroppableEnabled(e.item, false);
      },
      onEnd: (e) => this.setDroppableEnabled(e.item, true),
      onMove: (e) => {
        const index = $(e.related).index();
        if (e.to.dataset.controller === 'media-lib--droppable') { return true; }
        if (index === this.index) { return true; }
        return false;
      },
    });
  }

  // Private
  setDroppableEnabled(el, enabled) {
    const controller = this.application.getControllerForElementAndIdentifier(
      el.querySelector('[data-controller="media-lib--droppable"]'),
      'media-lib--droppable',
    );
    if (!controller) { return; }
    controller.setEnabled(enabled);
  }

  deselect = _.debounce((limit) => {
    if (limit <= 0) { return; }

    const dropped = _.dropRight(this.selected, limit);
    _.pull(this.selected, ...dropped);

    dropped.forEach((el) => {
      el.classList.remove('selected');
      Sortable.utils.deselect(el);
      triggerEvent(el, 'deselect');
    });
  }, 200);

  /* eslint-disable no-underscore-dangle */
  applyMultiDragDeselectFix() {
    const self = this;
    const MultiDragPlugin = new MultiDrag();
    const origDeselect = MultiDragPlugin.prototype._deselectMultiDrag;
    MultiDragPlugin.prototype._deselectMultiDrag = (event) => {
      // Caution: this is multidrag instance
      const ignored = event && !_.isEmpty(_.intersection(event.path, self.ignoreDeselectTargets));
      const outside = event && !$.contains(this.element, event.target);
      if (ignored || outside) { return; }

      origDeselect.call(self.sortable.multiDrag, event);
    };
    Sortable.mount(MultiDragPlugin);
  }
  /* eslint-enable no-underscore-dangle */
}
