import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image as KonvaImage } from 'react-konva';

function initImage(url, options, onLoad) {
  const image = new window.Image(options.width || 14, options.height || 14);
  let cancelled = false;
  image.onload = () => !cancelled && onLoad(image);
  image.src = url;
  image.cancelLoad = () => { cancelled = true; };
  return image;
}

export default function Image({
  url, width, height, ...props
}) {
  const [image, setImage] = useState();
  useEffect(() => {
    const img = initImage(url, { width, height }, () => setImage(img));
    return () => img.cancelLoad();
  }, [url, width, height, setImage]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <KonvaImage {...props} image={image} width={width} height={height} />;
}

Image.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
