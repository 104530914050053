import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Rect, Text } from 'react-konva';
import Theme from '../../util/theme';

export default function Button({
  index, title, onClick, last,
}) {
  const [active, setActive] = useState(false);
  let cornerRadius = 0;
  if (index === 0) { cornerRadius = [4, 4, 0, 0]; }
  if (last) { cornerRadius = [0, 0, 4, 4]; }

  const background = (
    <Rect
      x={1}
      y={1 + 26 * index}
      width={98}
      height={26}
      fill={Theme.COLOR_ACTIVE}
      cornerRadius={cornerRadius}
    />
  );
  return (
    <>
      {active && background}
      <Text
        x={10}
        y={1 + 26 * index}
        width={98}
        height={26}
        lineHeight={26}
        text={title}
        fill="white"
        opacity={active ? 1 : 0.5}
        fontFamily="Roboto"
        fontSize={13}
        verticalAlign="middle"
        onClick={onClick}
        onMouseDown={() => setActive(true)}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        onMouseUp={() => setActive(false)}
        onMouseOut={() => setActive(false)}
      />
    </>
  );
}

Button.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  last: PropTypes.bool,
};

Button.defaultProps = { last: false };
