import React from 'react';
import PropTypes from 'prop-types';

export default function Tab({ active, type }) {
  return (
    <li className="nav-item">
      <a className={`nav-link ${active ? 'active' : ''}`} id={`permission--tab-${type}`} data-toggle="tab" href={`#permission--content-${type}`} role="tab" aria-controls={type} aria-selected="true">
        {I18n.t(`permissions.index.${type}`)}
      </a>
    </li>
  );
}

Tab.defaultProps = {
  active: false,
};

Tab.propTypes = {
  type: PropTypes.string.isRequired,
  active: PropTypes.bool,
};
