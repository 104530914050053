import { useRef, useEffect } from 'react';
import produce from 'immer';
import _ from 'lodash';
import { getViewportPosition } from './viewport';

export function useDroppable(dispatch, scene) {
  const containerRef = useRef();
  const stageRef = useRef();

  useEffect(() => {
    const elem = containerRef.current;
    if (!elem) { return; }

    const onDragOver = (e) => e.preventDefault();
    const onDrop = (e) => {
      e.preventDefault();

      const stage = stageRef.current.getStage();
      if (!stage) { return; }

      const nodeConfig = _.attempt(() => JSON.parse(e.dataTransfer.getData('text')));
      if (_.isError(nodeConfig)) { return; }

      stage.setPointersPositions(e);
      const position = getViewportPosition(stage);
      dispatch({
        type: 'update-scene',
        scene: produce(scene, (draft) => {
          draft.nodes.push({
            positionX: position.x,
            positionY: position.y,
            className: nodeConfig.className,
            name: nodeConfig.name,
            typeSpecialization: nodeConfig.typeSpecialization,
            editable: true,
            input: {},
          });
        }),
      });
    };

    elem.addEventListener('dragover', onDragOver);
    elem.addEventListener('drop', onDrop);

    return () => {
      elem.removeEventListener('dragover', onDragOver);
      elem.removeEventListener('drop', onDrop);
    };
  }, [containerRef, stageRef, dispatch, scene]);

  return [containerRef, stageRef];
}

export default useDroppable;
