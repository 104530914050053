import React, { useState } from 'react';
import _ from 'lodash';
import Control from '../Control';
import { useCoreContext } from '../../Context';
import { destroyPermissions, createPermissions } from '../../util/actions';
import PermissionItem from '../PermissionItem';
import { useTooltip } from '../../../../util/ui';

const SPACE_BLOCKS = [
  'MigrationBlock::FlowSpaceBlock',
  'MigrationBlock::ContentSpaceBlock',
  'MigrationBlock::TemplateSpaceBlock',
  'MigrationBlock::ResourceSpaceBlock',
];
const STATE_BLOCKS = [
  'MigrationBlock::FlowStateBlock',
  'MigrationBlock::ContentStateBlock',
];

async function togglePermissions({
  names, type, setLoading, loading, permissions, dispatch,
}) {
  setLoading({ ...loading, [type]: true });
  const existingPermissions = _(names)
    .map((name) => _.find(permissions, { name }))
    .compact()
    .value();
  if (existingPermissions.length === names.length) {
    await destroyPermissions(dispatch, existingPermissions);
    return setLoading({ ...loading, space: false });
  }

  const newPermissions = _(names)
    .filter((name) => !_.find(permissions, { name }))
    .map((name) => ({ name, kind: 'entity_read' }))
    .value();
  await createPermissions(dispatch, newPermissions);
  return setLoading({ ...loading, [type]: false });
}

export default function Migration() {
  const { permissions, dispatch } = useCoreContext();
  const spaceTitleTooltip = useTooltip();

  const migrationEnabled = !!_.find(permissions, { name: 'Migration', kind: 'entity_read' });
  const spaceEnabled = _.every(SPACE_BLOCKS, (name) => _.find(permissions, { name, kind: 'entity_read' }));
  const stateEnabled = _.every(STATE_BLOCKS, (name) => _.find(permissions, { name, kind: 'entity_read' }));

  const [loading, setLoading] = useState({ migration: false, space: false, state: false });
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };
  const togglePermissionsOptions = {
    setLoading,
    loading,
    permissions,
    dispatch,
  };
  const toggleMigrationSpace = () => togglePermissions({ names: SPACE_BLOCKS, type: 'space', ...togglePermissionsOptions });
  const toggleMigrationState = () => togglePermissions({ names: STATE_BLOCKS, type: 'state', ...togglePermissionsOptions });

  const spaceTitle = (
    <>
      {I18n.t('permissions.migration_permissions.space')}
      <i
        className="fa fa-exclamation-triangle ml-2"
        data-toggle="tooltip"
        data-placement="top"
        title={I18n.t('permissions.migration_permissions.space_tooltip')}
        ref={spaceTitleTooltip}
      />
    </>
  );
  return (
    <div className="permission-category" id="permission-category-migrations">
      <div className="permission-category-header">
        <div className="permissions-row-grid">
          <div className="mi">
            <span>{I18n.t('permissions.feature_permission.Migration')}</span>
          </div>
          <Control name="Migration" kind="entity_read" />
          <div className="mi" />
          <div className="mi" />
          <div className="mi" />
          <div className="permission-toggle">
            <a href="#" onClick={toggleExpanded} aria-expanded={expanded} aria-controls="permission-migrations">
              <i className="fa fa-chevron-down" />
            </a>
          </div>
        </div>
      </div>
      <div className={`permission-category-body collapse ${expanded ? 'show' : ''}`} id="permission-migrations">
        <ul>
          <PermissionItem title={spaceTitle}>
            <Control
              name="MigrationSpace"
              kind="entity_read"
              disabled={migrationEnabled}
              checked={spaceEnabled}
              loading={loading.space}
              onChange={toggleMigrationSpace}
            />
          </PermissionItem>
          <PermissionItem title={I18n.t('permissions.migration_permissions.state')}>
            <Control
              name="MigrationState"
              kind="entity_read"
              disabled={migrationEnabled}
              checked={stateEnabled}
              loading={loading.state}
              onChange={toggleMigrationState}
            />
          </PermissionItem>
        </ul>
      </div>
    </div>
  );
}
