export function filterOKResponse(response) {
  if (!response.ok) {
    console.warn('failed to fetch', response); // eslint-disable-line no-console
    throw Error(response.statusText);
  }
  return response;
}

export function fetchWithAuth(url, options) {
  return fetch(url, {
    credentials: 'include', // Include credentials including cookies
    ...(options || {}),
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      ...(options?.headers ?? {}),
    },
  });
}
