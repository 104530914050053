import React from 'react';
import PropTypes from 'prop-types';
import Plug from '../Plug';
import { nodePropType, inputPropType, connectionPropType } from '../../../../util/flow/types';
import Field, { fieldHasPlug, fieldComponent } from './Field';

function hasPlug(input, node) {
  return fieldHasPlug(input, node);
}

export default function Input({
  input, top, node, connections, onInteractionStateChange,
}) {
  return (
    <>
      <Field
        x={10}
        y={top}
        input={input}
        node={node}
        connections={connections}
        onInteractionStateChange={onInteractionStateChange}
      />
      {hasPlug(input, node) ? (
        <Plug
          x={-30}
          y={top}
          direction="in"
          type="data"
          dataType={input.type}
          secondaryDataType={input.arrayType}
          name={input.name}
          borders="left"
          connections={connections}
          node={node}
          inputFieldComponentCallback={fieldComponent}
        />
      ) : null}
    </>
  );
}

Input.propTypes = {
  input: inputPropType.isRequired,
  top: PropTypes.number.isRequired,
  node: nodePropType.isRequired,
  onInteractionStateChange: PropTypes.func.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};
