import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { nodePropType, nodeConfigPropType, connectionPropType } from '../../../../util/flow/types';
import { nodeHasActionInput } from '../../../../util/flow/node';
import { ioNameFromKey } from '../../../../util/flow/plug/core';
import Input from './Input';
import { fieldComponent } from './Field';
import { inputY } from '../../../../util/flow/ui/height';

export default function Inputs({ node, config, connections }) {
  const [interactiveInput, setInteractiveInput] = useState();

  const top = 10 + (nodeHasActionInput(node.className) ? 40 : 0);


  const inputs = _(config.inputs || [])
    .sortBy((a) => (a === interactiveInput ? 1 : 0))
    .map((input) => (
      <Input
        key={input.name}
        top={top + inputY(input, config, node, fieldComponent)}
        input={input}
        node={node}
        onInteractionStateChange={(active) => active && setInteractiveInput(input)}
        connections={_.filter(connections, (conn) => (
          conn.rightId === node.id
          && ioNameFromKey(conn.rightKey) === input.name
        ))}
      />
    ))
    .value();
  return (
    <>
      {inputs}
    </>
  );
}

Inputs.propTypes = {
  node: nodePropType.isRequired,
  config: nodeConfigPropType.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};
