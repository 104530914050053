import _ from 'lodash';

const mapKeysDeep = (obj, cb) => {
  if (!_.isPlainObject(obj)) { return obj; }

  return _.mapValues(_.mapKeys(obj, cb), (val) => {
    if (_.isPlainObject(val)) { return mapKeysDeep(val, cb); }
    if (_.isArray(val)) { return _.map(val, (item) => mapKeysDeep(item, cb)); }
    return val;
  });
};

function inputParams(node, connections) {
  const input = mapKeysDeep(node.input, (_value, key) => _.snakeCase(key));
  _(connections)
    .filter({ rightId: node.id })
    .map('rightKey')
    .compact()
    .each((k) => _.unset(input, k));
  return input;
}

export function sceneParams(scene) {
  return {
    flow_scene: {
      id: scene.id,
      nodes_attributes: scene.nodes.map((node) => ({
        id: node.id,
        position_x: node.positionX,
        position_y: node.positionY,
        type: node.className,
        input: inputParams(node, scene.connections),
        editable: node.editable,
        type_specialization: _.snakeCase(node.typeSpecialization),
        _destroy: node.destroyed,
      })),
      connections_attributes: scene.connections.map((connection) => ({
        id: connection.id,
        type: connection.type,
        left_id: connection.leftId,
        left_key: connection.leftKey,
        right_id: connection.rightId,
        right_key: connection.rightKey,
        _destroy: connection.destroyed,
      })),
    },
  };
}

export default sceneParams;
