import _ from 'lodash';

function resetPlugConnections(draft, { node: { id }, key }) {
  _(draft.scene.connections)
    .filter((con) => (con.leftId === id && con.leftKey === key)
      || (con.rightId === id && con.rightKey === key))
    .each((con) => { con.destroyed = true; }); // eslint-disable-line no-param-reassign
}

export default function reducer(draft, action) {
  switch (action.type) {
    case 'reset-plug-connections':
      return resetPlugConnections(draft, action);
    case 'register-plug':
      _.set(draft, ['refs', 'plugs', action.id], action.plug);
      break;
    case 'unregister-plug':
      _.unset(draft, ['refs', 'plugs', action.id]);
      break;
    default: break;
  }
}
