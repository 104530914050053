import { Controller } from 'stimulus';

function parseSources(el) {
  return JSON.parse(el.dataset.replaceTextSources);
}

function parseReplacements(el) {
  return JSON.parse(el.dataset.replaceTextReplacements);
}

function replaceText(el) {
  const sources = parseSources(el);
  const replacements = parseReplacements(el);
  // eslint-disable-next-line no-param-reassign
  el.innerHTML = sources.reduce(
    (html, source, index) => html.replaceAll(source, replacements[index]),
    el.innerHTML,
  );
  return el.outerHTML;
}

export default class extends Controller {
  connect() {
    const el = $(this.element.innerHTML);
    el.find('[data-replace-text-sources]').replaceWith(
      function replaceWithCallback() { return replaceText(this); },
    );
    this.element.innerHTML = el[0].outerHTML;
  }
}
