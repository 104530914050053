import { isEmpty } from 'lodash';
import { Controller } from 'stimulus';
import { triggerEvent } from '../../app/event';
import { updateInputValue } from '../../util/ui';

export default class extends Controller {
  static targets = ['input', 'target'];

  change() {
    const notBlank = this.inputTargets.some((input) => !isEmpty($(input).val()));
    this.targetTargets.forEach((el) => el.classList[notBlank ? 'remove' : 'add']('hide'));
  }

  clear() {
    this.inputTargets.forEach((element) => {
      if (isEmpty($(element).val())) { return; }

      updateInputValue(element, '');
      triggerEvent(element, 'change');
    });

    if (this.data.get('on-clear')) {
      // eslint-disable-next-line no-eval
      eval(this.data.get('on-clear'));
    }
  }
}
