import React, { useEffect, useCallback, useMemo } from 'react';
import { Stage as KonvaStage, Layer } from 'react-konva';
import Scene from './Scene';
import { configPropType, scenePropType } from '../../util/flow/types';
import { findSnapPlug } from '../../util/flow/plug/ui';
import Drawing from './Drawing';
import { useFlowState } from './util/hooks/core';
import { useViewport, getViewportPosition } from './util/hooks/viewport';
import { useDroppable } from './util/hooks/droppable';
import ContextProvider from './util/ContextProvider';
import { triggerEvent } from '../../app/event';
import { fitToScene } from '../../util/flow/scene';
import { fieldComponent } from './Nodes/Inputs/Field';

export default function Stage({ scene: initialScene, config }) {
  const [{ scene, drawing, refs }, dispatch] = useFlowState(initialScene);
  const [containerRef, stageRef] = useDroppable(dispatch, scene);
  const [bounds, scale, { onScroll, onDrag, setViewport }] = useViewport(containerRef);
  const lockViewport = useMemo(
    () => refs.activeElement?.lockViewport ?? false,
    [refs.activeElement],
  );
  const handleDrawEnd = useCallback(() => {
    const plug = findSnapPlug(
      drawing.connection,
      refs.plugs,
      config,
      getViewportPosition(stageRef.current),
      fieldComponent,
    );
    dispatch({ type: 'draw-action-end', plug: plug?.plug });
  }, [dispatch, drawing, refs, config, stageRef]);

  useEffect(() => {
    const viewport = fitToScene(initialScene, config, containerRef);
    if (viewport) { setViewport(viewport); }
  }, [initialScene, config, setViewport, containerRef]);

  useEffect(() => {
    if (!stageRef.current) { return; }
    const stage = stageRef.current.getStage();
    stage.on('contentContextmenu', (e) => {
      e.evt.preventDefault();
    });
    return () => stage.off('contentContextmenu');
  }, [stageRef]);

  useEffect(() => {
    triggerEvent(containerRef.current, 'change', { scene });
  }, [scene, containerRef]);

  return (
    <div className="fe-inner" data-action="change->flow--simulation-form#refreshEventNodes" ref={containerRef}>
      <KonvaStage
        x={bounds.x}
        y={bounds.y}
        width={bounds.width}
        height={bounds.height}
        ref={stageRef}
        scaleX={scale.x}
        scaleY={scale.y}
        onWheel={lockViewport ? (() => {}) : onScroll}
        draggable={!lockViewport}
        onDragEnd={lockViewport ? (() => { }) : onDrag}
        onMouseUp={handleDrawEnd}
      >
        <Layer>
          <ContextProvider
            stage={stageRef.current}
            config={config}
            dispatch={dispatch}
            drawing={drawing}
            scene={scene}
            refs={refs}
          >
            <Scene />
            <Drawing />
          </ContextProvider>
        </Layer>
      </KonvaStage>
    </div>
  );
}

Stage.propTypes = {
  scene: scenePropType.isRequired,
  config: configPropType.isRequired,
};
