import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import produce from 'immer';
import { getNodeInputValue } from '../../../../../util/flow/node';
import { editableFieldPropTypes } from '../types';
import ArrayTextField from './ArrayTextField';
import { CONNECTION_MARGIN } from '../../../util/theme';
import { useDispatch } from '../../../util/hooks/core';
import { positionPropTypes } from '../../../../../util/flow/types';
import EditText from '../EditText';
import { Plus, Minus } from '../../../util/Images';

function valuesFromNode(node, input) {
  const value = getNodeInputValue(node, input);
  if (_.isEmpty(value)) { return [input.name]; }
  return value;
}

function ArrayTextFields({
  x, y, values, updateNode, onButtonAction, editable,
}) {
  let top = y;
  const fields = values.map((value, index) => {
    const field = (
      <ArrayTextField
        key={index} // eslint-disable-line react/no-array-index-key
        x={x}
        y={top}
        text={value}
        editable={editable}
        imageComponent={index === values.length - 1 ? Plus : Minus}
        onChange={(text, intermediate) => updateNode(text, index, intermediate)}
        onButtonAction={() => onButtonAction(index)}
      />
    );
    top += EditText.height(value);
    return field;
  });
  return <>{fields}</>;
}

ArrayTextFields.propTypes = {
  ...positionPropTypes,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateNode: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  onButtonAction: PropTypes.func.isRequired,
};

export default function ArrayField({
  x, y, input, node, editable,
}) {
  const dispatch = useDispatch();
  const inputKey = _.camelCase(input.name);
  const values = valuesFromNode(node, input);

  const updateNode = useCallback((value, index, intermediate) => dispatch({
    type: `update-node${intermediate ? '-intermediate' : ''}`,
    node: produce(node, (draft) => {
      if (!draft.input[inputKey] || _.isEmpty(draft.input[inputKey])) {
        draft.input[inputKey] = [input.name];
      }
      if (value) {
        draft.input[inputKey][index] = value;
      } else {
        draft.input[inputKey].splice(index, 1);
      }
    }),
  }), [dispatch, node, inputKey, input.name]);

  const onButtonAction = useCallback((index) => {
    // Remove value
    if (index < values.length - 1) { return updateNode(null, index); }

    // Add value at end
    updateNode(_.startCase(input.name), index + 1);
  }, [updateNode, input, values]);

  return (
    <ArrayTextFields
      x={x}
      y={y}
      editable={editable}
      values={values}
      updateNode={updateNode}
      onButtonAction={editable ? onButtonAction : () => {}}
    />
  );
}
ArrayField.propTypes = editableFieldPropTypes;

ArrayField.height = (input, node) => (
  CONNECTION_MARGIN + _(valuesFromNode(node, input)).map((value) => EditText.height(value)).sum()
);
