import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-konva';
import { positionPropTypes } from '../../../util/flow/types';
import { addPoints } from '../../../util/flow/ui/point';

const straightLineLength = 30;
const defaultStyle = {
  strokeWidth: 2,
};

/* eslint-disable react/jsx-props-no-spreading */
export default function ConnectionLine({ leftPosition, rightPosition, color }) {
  const curveStartPosition = addPoints(leftPosition, { x: straightLineLength, y: 0 });
  const curveEndPosition = addPoints(rightPosition, { x: -straightLineLength, y: 0 });
  return (
    <>
      <Line
        stroke={color}
        points={[leftPosition.x, leftPosition.y, curveStartPosition.x, curveStartPosition.y]}
        {...defaultStyle}
      />
      <Line
        stroke={color}
        points={[
          curveStartPosition.x, curveStartPosition.y,
          (curveStartPosition.x + curveEndPosition.x) / 2, curveStartPosition.y,
          (curveStartPosition.x + curveEndPosition.x) / 2, curveEndPosition.y,
          curveEndPosition.x, curveEndPosition.y,
        ]}
        bezier
        {...defaultStyle}
      />
      <Line
        stroke={color}
        points={[rightPosition.x, rightPosition.y, curveEndPosition.x, curveEndPosition.y]}
        {...defaultStyle}
      />
    </>
  );
}
/* eslint-enable react/jsx-props-no-spreading */

ConnectionLine.propTypes = {
  leftPosition: PropTypes.shape(positionPropTypes).isRequired,
  rightPosition: PropTypes.shape(positionPropTypes).isRequired,
  color: PropTypes.string.isRequired,
};
