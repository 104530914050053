import { Controller } from 'stimulus';

const OFFSET = 1000;
export default class extends Controller {
  connect() {
    $(this.element).off('scroll').scroll(({ currentTarget }) => {
      const url = $(currentTarget).find('.pagination span.next a').attr('href');
      if (url && $(currentTarget).scrollTop() + $(currentTarget).innerHeight()
        >= currentTarget.scrollHeight - OFFSET) {
        $('.pagination').text('Fetching more');
        $.getScript(url);
      }
    });
  }
}
