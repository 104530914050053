import React from 'react';
import PropTypes from 'prop-types';
import Plug from '../Plug';
import PlugLabel from '../Plug/PlugLabel';
import { nodePropType, connectionPropType } from '../../../../util/flow/types';
import { nodeHasActionInput } from '../../../../util/flow/node';
import { CONNECTION_MARGIN, CONNECTION_HEIGHT } from '../../util/theme';

export function actionInputsHeight(nodeConfig) {
  return nodeHasActionInput(nodeConfig.className) ? (CONNECTION_MARGIN + CONNECTION_HEIGHT) : 0;
}

export default function ActionInput({ node, connections }) {
  if (!nodeHasActionInput(node.className)) { return null; }

  return (
    <>
      <PlugLabel x={140} y={10} text="" align="left" />
      <Plug x={-30} y={10} direction="in" type="logic" connections={connections} node={node} name={null} />
    </>
  );
}

ActionInput.propTypes = {
  node: nodePropType.isRequired,
  connections: PropTypes.arrayOf(connectionPropType).isRequired,
};
