import { Controller } from 'stimulus';
import _ from 'lodash';
import { triggerEvent } from '../../app/event';

export default class extends Controller {
  static targets = ['container', 'idInput', 'icon', 'preview', 'previewImage'];

  update(event) {
    const data = _.first(event.data);
    if (!data) { return; }

    this.idInputTarget.value = data.id;
    this.iconTarget.className = `fa ${data.icon}`;
    this.containerTarget.className = `ml-item ml-item-${data.type}`;
    if (data.preview) {
      this.previewTarget.classList.remove('hide');
      this.previewImageTarget.src = data.preview;
    } else {
      this.previewTarget.classList.add('hide');
    }

    triggerEvent(this.idInputTarget.form, 'change');
  }
}
