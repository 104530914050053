import { Controller } from 'stimulus';
import _ from 'lodash';

export default class extends Controller {
  static targets = ['containerTimetable', 'containerZone', 'containerProduct', 'containerAb'];

  updateContainer({ target: { value } }) {
    const container = _.camelCase(`container_${value}`);
    _.without(['containerTimetable', 'containerZone', 'containerProduct', 'containerAb'], container).forEach((c) => this[`${c}Target`].classList.add('hide'));
    this[`${container}Target`].classList.remove('hide');
  }
}
