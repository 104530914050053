import React from 'react';
import PropTypes from 'prop-types';
import { Group } from 'react-konva';
import PlugBackground from './PlugBackground';
import { positionPropTypes } from '../../../../util/flow/types';

export default function ImagePlug({
  x, y, direction, onClick, Image,
}) {
  return (
    <Group
      x={x}
      y={y}
      width={30}
      height={30}
      onClick={onClick}
    >
      <PlugBackground direction={direction} />
      <Image width={30} height={30} />
    </Group>
  );
}

ImagePlug.propTypes = {
  ...positionPropTypes,
  direction: PropTypes.oneOf(['in', 'out']).isRequired,
  onClick: PropTypes.func.isRequired,
  Image: PropTypes.func.isRequired,
};
