import { Controller } from 'stimulus';
import _ from 'lodash';
import { triggerEvent } from '../../app/event';
import consumer from '../../app/consumer';

function hiddenInput(index, name, value) {
  return $('<input>').attr({
    name: `migration[blocks_attributes][${index}][${name}]`,
    value,
    type: 'hidden',
  });
}

export default class extends Controller {
  static targets = ['newBlockForm', 'block', 'blocks', 'blockForm', 'nowCheckbox', 'dateContainer', 'dateInput', 'output', 'saveButton'];

  connect() {
    if (!this.id) { return; }
    const self = this;
    this.subscription = consumer.subscriptions.create({ channel: 'MigrationChannel', id: this.id }, {
      received(data) {
        switch (data.type) {
          case 'console_output':
            return self.updateConsole(data.html);
          case 'migration_job_finished':
            Rails.enableElement(self.saveButtonTarget);
            self.saveButtonTarget.classList.remove('disabled');
            break;
          default: break;
        }
      },
    });
  }

  disconnect() {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

  addBlock(block) {
    const index = this.blockFormTargets.length;
    $(this.blocksTarget).append(hiddenInput(index, 'block_type', block.type));
    $(this.blocksTarget).append(hiddenInput(index, 'position', index));
    triggerEvent(this.element, 'refresh');
  }

  toggleDateVisibility() {
    this.dateContainerTarget.classList[this.nowCheckboxTarget.checked ? 'add' : 'remove']('hide');
    // eslint-disable-next-line no-underscore-dangle
    this.dateInputTarget._flatpickr.setDate(
      this.nowCheckboxTarget.checked ? null : new Date(new Date().getTime() + 30 * 60 * 1000),
    );
  }

  refreshPositions() {
    _.each(this.blockTargets, (block, index) => {
      const positionInput = block.querySelector('input[id$=_position]');
      positionInput.value = index;
    });
  }

  updateConsole(html) {
    $(this.outputTarget).replaceWith(html);
  }

  get newBlockFormController() {
    return this.application.getControllerForElementAndIdentifier(this.newBlockFormTarget, 'migration--new-block');
  }

  get id() {
    return this.data.get('id');
  }
}
