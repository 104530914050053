import { Controller } from 'stimulus';
import _ from 'lodash';
import { filterOKResponse, fetchWithAuth } from '../../util/network';
import { getElementPath } from '../../util/ui';

export default class extends Controller {
  replaceForm(e) {
    if (e.target.classList.contains('flatpickr-input')) {
      const flatpickr = e.target._flatpickr; // eslint-disable-line no-underscore-dangle
      if (flatpickr?.isOpen) {
        _.pull(flatpickr.config.onClose, this.onFlatpickrClose);
        flatpickr.config.onClose.push(this.onFlatpickrClose);
        return;
      }
    }

    fetchWithAuth(this.url, {
      method: 'PUT',
      body: new FormData(this.form[0]),
    })
      .then(filterOKResponse)
      .then((response) => response.text())
      .then((markup) => {
        this.hideActiveTooltips();
        const { activeElement } = document;
        const selector = getElementPath(activeElement);
        this.form.replaceWith(markup);
        $(selector).focus();
      });
  }

  onFlatpickrClose = (_selectedDates, _dateStr, flatpickr) => {
    _.pull(flatpickr.config.onClose, this.onFlatpickrClose);
    this.replaceForm({ target: flatpickr.element });
  }

  hideActiveTooltips() {
    this.form.find('[data-toggle="tooltip"]').tooltip('hide');
  }

  get url() {
    return this.data.get('url');
  }

  get form() {
    return $(this.hasFormTarget ? this.formTarget : this.element);
  }
}
