import { Controller } from 'stimulus';

const EDIT_CLASS = 'ml-item-edit';

export default class extends Controller {
  static targets = ['nameInput', 'form'];

  connect() {
    this.name = this.nameInputTarget.value;
  }

  rename(e) {
    e.preventDefault();

    this.isEditing = !this.isEditing;
    if (this.isEditing || this.name === this.nameInputTarget.value) { return; }
    Rails.fire(this.formTarget, 'submit');
  }

  create(e) {
    e.preventDefault();
    Rails.fire(this.formTarget, 'submit');
  }

  get id() { return this.element.dataset.resourceId; }

  get isEditing() { return this.element.classList.contains(EDIT_CLASS); }

  set isEditing(value) {
    this.element.classList[value ? 'add' : 'remove'](EDIT_CLASS);
    this.nameInputTarget[value ? 'removeAttribute' : 'setAttribute']('readonly', value);
  }
}
