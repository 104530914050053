import { Controller } from 'stimulus';
import _ from 'lodash';
import Sortable from 'sortablejs';
import { updateResourceChildren } from './resources_controller';

export default class extends Controller {
  connect() {
    this.sortable = new Sortable(this.element, {
      group: 'media-lib',
      draggable: '.invalid-selector-to-disable-dragging',
      onAdd: (e) => {
        const items = !_.isEmpty(e.items) ? e.items : [e.item];
        updateResourceChildren(this.data.get('url'), _.map(items, (el) => el.dataset.resourceId));
      },
    });
  }

  setEnabled(enabled) {
    this.sortable.option('disabled', !enabled);
  }
}
